var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "v-card--material px-5 py-3",
      attrs: { tile: _vm.tile, outlined: _vm.outlined, flat: _vm.flat },
    },
    [
      !_vm.loading
        ? [
            _vm._t("heading", function () {
              return [
                _vm.title
                  ? _c("card-heading", {
                      attrs: { color: _vm.color, title: _vm.title },
                    })
                  : _vm._e(),
              ]
            }),
            _c(
              "v-card-text",
              { staticClass: "overflow-x-auto", class: _vm.stretch },
              [
                _vm._l(_vm.rows, function (row, rowIndex) {
                  return [
                    _c(
                      "v-slide-x-transition",
                      { key: `${row.key}-${rowIndex}` },
                      [
                        _c(
                          "v-row",
                          {
                            key: `${row.key}-${rowIndex}`,
                            staticClass: "flex-nowrap",
                            attrs: { dense: "" },
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "highlight align-center",
                                attrs: { cols: 2 },
                              },
                              [
                                rowIndex === 0
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "error--text",
                                        attrs: { text: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$emit("closeAll")
                                          },
                                        },
                                      },
                                      [_vm._v(" Close all ")]
                                    )
                                  : _c(
                                      "v-list-item",
                                      [
                                        _c(
                                          "v-list-item-content",
                                          { staticClass: "text-body-1" },
                                          [
                                            _vm._t(
                                              `row.${row.key}.label`,
                                              function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(row.label) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              { row: row }
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            ),
                            _vm._l(_vm.items, function (item, itemIndex) {
                              return _c(
                                "v-col",
                                {
                                  key: `${row.key}--${rowIndex}-${itemIndex}`,
                                  staticClass: "highlight",
                                  attrs: { cols: _vm._cols },
                                },
                                [
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-content",
                                        { staticClass: "text-body-1" },
                                        [
                                          _vm._t(
                                            `row.${row.key}.value`,
                                            function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item[row.key]) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            {
                                              row: row,
                                              items: _vm.items,
                                              diff:
                                                item[row.key] -
                                                _vm.items[0][row.key],
                                              item: item,
                                              index: itemIndex,
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    rowIndex < _vm.rows.length - 1
                      ? _c("v-divider", { key: `divider${rowIndex}` })
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }