import localstorage from "./localstorage";
export default {
	mixins: [localstorage],
	data() {
		return {
			footerProps: {
				"items-per-page-options": [5, 10, 15, 25, 50, 100],
			},
			options: {},
			total_records: 0,
			loading: false,
			footerStorage: {},
		};
	},
	computed: {
		filteredHeaders() {
			return this.headers.filter((el) => !el.hide?.());
		},
	},
	methods: {
		itemClass() {
			return `table-item${this.rowIsClickable ? " table-item--clickable" : ""}`;
		},
		async _loadTable() {
			this.loading = true;
			try {
				if (!this.loadList) {
					this.loading = false;
					return;
				}
				const paging = await this.loadList();
				if (paging === -1) return;
				this.total_records = paging?.total_records;
				this.loading = false;
			} catch (e) {
				if (e?.message === "canceled") {
					return;
				}
				this.loading = false;
			}
		},
	},
	created() {
		if (this.noOptions) return;
		const tempOpt = this.$getFromStorage("options");
		if (tempOpt) {
			delete tempOpt.page;
			this.options = tempOpt;
		}
		this.$syncWithStorage("options", this.$route.name);
		this.$watch(
			"options",
			(newVal) => {
				this.setPagination({
					page: newVal.page,
					per_page: newVal.itemsPerPage,
					order: newVal.sortDesc?.[0] ? "desc" : "asc",
					//THIS HAS TO BE DONE, SORRY
					//NO IT DOES NOT
					order_by: newVal.sortBy?.[0],
				});
				this._loadTable();
			},
			{ deep: true }
		);
	},
};
