<template>
	<v-container
		id="Statistics"
		fluid
		tag="section">
		<FilterSideBar
			@clear="onClear"
			refreshButton
			:refreshText="'Reload Data'"
			@refresh="refreshCurrentTab"
			:width="isMobile ? '' : '400px'"
			:loading="tableLoading"
			:filterIsEmpty="filterIsEmpty"
			:filterIsActive="filterIsActive">
			<StatisticsSumForm
				:filterIsActive="filterIsActive"
				ref="filter"
				v-model="filter"
				:loading="tableLoading"
				:scope="scope"
				@update="onFilterUpdate" />
		</FilterSideBar>
		<v-snackbar
			style="position: fixed; bottom: 20px; text-align: center"
			app
			transition="slide-y-reverse-transition"
			:timeout="1000"
			color="menu_background"
			content-class="kajot-text--text"
			v-model="copied">
			<div class="align-center">
				<v-icon color="info">mdi-information</v-icon>
				Tab URL copied to clipboard.
			</div>
			<template #action>
				<v-btn
					fab
					text
					x-small
					@click="copied = false">
					<v-icon
						color="kajot-text"
						small>
						mdi-close
					</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
		<v-row dense>
			<v-col>
				<v-card class="mt-0">
					<div class="d-flex flex-row flex-wrap">
						<div
							class="sliderElementWrapper"
							@click="
								() => {
									if (!tableLoading) tabClick(-1);
								}
							">
							<div
								v-ripple
								class="sliderElement py-2 text-truncate d-flex justify-center align-center"
								:class="{ active: isActive(-1) }"
								:style="{
									minHeight: tabHeight * 20 + 'px',
									height: '100%',
								}">
								<div class="d-flex flex-column">
									<v-icon
										:color="isActive(-1) ? 'primary' : ''"
										class="align-center">
										mdi-home
									</v-icon>
									<v-btn
										v-if="this.tabs.length > 0"
										text
										@click="closeAllTabs"
										style="z-index: 2"
										class="error--text">
										Close all
									</v-btn>
								</div>
							</div>
						</div>
						<div
							class="sliderElementWrapper d-flex flex-row"
							v-for="(tabDef, index) of tabs"
							:draggable="!tableLoading"
							@dragover="(e) => dragOver(e, index)"
							@dragenter="(e) => dragEnter(e, index)"
							@dragleave="(e) => dragLeave(e, index)"
							@dragstart="dragStart"
							@dragend="(e) => dragEnd(e, index)"
							@drop="(e) => onDrop(e, index)"
							:class="{ active: isActive(index) }"
							@click="
								() => {
									tabClick(index);
								}
							"
							:data-index="index"
							:key="`${tabDef.title}-${index}-${tabDef.count}`"
							v-ripple
							:style="{
								minHeight: tabHeight * 20 + 'px',
								maxWidth: tabs.length >= 10 ? '10%' : 100 / tabs.length + '%',
							}"
							:ref="`tab-${index}`">
							<v-tooltip
								top
								class="red">
								<template #activator="{ on, attrs }">
									<v-icon
										v-if="tabDef.filter.flags.length > 0"
										color="warning"
										v-on="on"
										v-bind="attrs"
										class="mt-1 ml-1"
										style="z-index: 1"
										size="20px">
										mdi-information
									</v-icon>
								</template>
								<span>
									<tr>Active Flags:</tr>
									<tr
										v-for="flag of tabDef.filter.flags"
										:key="flag">
										{{ round_flags[flag].tooltip }}
									</tr>
								</span>
							</v-tooltip>
							<div
								:class="{ active: isActive(index) }"
								class="sliderElement py-2 text-truncate d-flex flex-column justify-center align-center pl-1"
								:style="{
									minHeight: tabHeight * 20 + 'px',
								}">
								<v-progress-circular
									indeterminate
									v-if="isActive(index) && tableLoading"></v-progress-circular>
								<div v-else>
									<div
										v-for="(element, elIndex) of tabDef.title.split(` @ `)"
										:key="element"
										class="text-truncate">
										{{ element }}
										<span v-if="elIndex == 0 && tabDef.count > 1">
											( {{ tabDef.count }} )
										</span>
									</div>
								</div>
							</div>
							<div class="pr-1">
								<v-icon
									:ripple="false"
									@click.stop="removeTab(index)"
									class="sliderElementClose"
									color="error"
									size="20px">
									mdi-close
								</v-icon>
							</div>
						</div>
					</div>

					<div>
						<v-slide-x-transition hide-on-leave>
							<v-card
								v-show="isActive(-1)"
								flat>
								<v-card-title class="ml-1 mb-1">
									<div class="d-flex flex-row align-center">
										<v-icon
											ref="homeCopy"
											class="mr-3"
											@click="onCopyHome">
											mdi-content-copy
										</v-icon>
										<div class="d-flex flex-column mb-1">
											<span class="primary--text">
												{{
													filterValues?.casinos?.length === 1
														? `${casinoParse(filterValues.casinos[0])}`
														: ""
												}}
											</span>
											{{ scope | Capitalize }} statistics -
											{{ timeToText(filterValues) }}
										</div>
									</div>
									<v-spacer></v-spacer>

									<v-btn
										v-if="
											_usrFlagsSome({
												key: `statistic`,
												val: permissions.READ,
											})
										"
										elevation="1"
										fab
										small
										color="primary"
										@click="onExportOpen('statistics')">
										<v-icon>mdi-arrow-down-bold-circle</v-icon>
									</v-btn>
								</v-card-title>
								<v-card-text class="px-1 px-md-3">
									<StatisticsTable
										ref="stats-home"
										:disabledFields="disabledFields"
										dataType="classic"
										@copyTab="onCopyHome"
										tabKey="rootTab"
										aggregator="'casino'"
										:allowedCasinos="allowedCasinos"
										:headers="perCasinoHeaders"
										:filter="filterValues"
										:loadList="(e, r) => _loadPerCasino(e, r)"
										:graphLabels.sync="graphLabels"
										:graphType.sync="graphType"
										:loadParams="rootParams"
										:level="0"
										:scope="scope"
										:showGraph="showGraph"
										:_options.sync="options"
										@openDetail="onCasinoRowClick"
										@addToCompare="onCasinoCompare"
										@toggleGraph="toggleGraph"
										@loading="handleLoading">
										<template #exportButton></template>
										<template #[`item.rtp`]="{ item }">
											<v-tooltip
												:disabled="
													!filterValues.flags.includes('joker_bonus') ||
													!_usrFlagsSome({
														key: 'statistic',
														val: permissions.READ,
													})
												"
												bottom>
												<template v-slot:activator="{ on, attrs }">
													<span
														:class="rtpStyle(item.rtp)"
														v-bind="attrs"
														v-on="on">
														{{ item.rtp | Percentage }}
													</span>
												</template>
												<table class="tooltipTable">
													<tr>
														<td>Total:</td>
														<td>
															{{ item.rtp | Percentage }}
														</td>
													</tr>
													<tr>
														<td>Rounds:</td>
														<td>
															{{
																(wAvg(
																	item.sum_win,
																	item.sum_bet,
																	item.data.global.joker_bonus.sum_win,
																	false
																) ?? 0) | Percentage
															}}
														</td>
													</tr>
													<tr v-if="filterValues.flags.includes('joker_bonus')">
														<td>Joker Bonus:</td>
														<td>
															+{{
																(wAvg(
																	item.sum_win,
																	item.sum_bet,
																	item.data.global.joker_bonus.sum_win
																) ?? 0) | Percentage
															}}
														</td>
													</tr>
												</table>
											</v-tooltip>
										</template>
										<template #[`item.rounds_count`]="{ item }">
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<span
														v-bind="attrs"
														v-on="on">
														{{
															item.rounds_count
																| Number({
																	style: "decimal",
																	minimumFractionDigits: 0,
																	maximumFractionDigits: 0,
																})
														}}
													</span>
												</template>
												<table class="tooltipTable">
													<tr>
														<td>Classic:</td>
														<td>
															{{
																item.data.global.classic?.rounds_count
																	| Number({
																		style: "decimal",
																		minimumFractionDigits: 0,
																		maximumFractionDigits: 0,
																	})
															}}
														</td>
													</tr>
													<tr v-if="filterValues.flags.includes('free_round')">
														<td>Free Rounds:</td>
														<td>
															{{
																item.data.global.free_round?.rounds_count
																	| Number({
																		style: "decimal",
																		minimumFractionDigits: 0,
																		maximumFractionDigits: 0,
																	})
															}}
														</td>
													</tr>
												</table>
											</v-tooltip>
										</template>
										<template #[`item.date_from`]="{ item }">
											{{ item.date_from | Date }}
										</template>
										<template #[`item.date_to`]="{ item }">
											{{ item.date_to | Date }}
										</template>
										<template #[`item.avg_bet`]="{ item }">
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<div
														v-bind="attrs"
														v-on="on"
														style="position: relative"
														:class="styleThis('avg_bet', item.avg_bet)">
														{{
															item.avg_bet
																| Number({
																	minimumFractionDigits: item.minor_units,
																	maximumFractionDigits: item.minor_units,
																	currency: item.currency || "EUR",
																})
														}}
													</div>
												</template>
												<table class="tooltipTable">
													<tr>
														<td>Classic:</td>
														<td>
															{{
																item.data.global.classic.avg_bet
																	| Number({
																		minimumFractionDigits: item.minor_units,
																		maximumFractionDigits: item.minor_units,
																		currency: item.currency || "EUR",
																	})
															}}
														</td>
													</tr>
													<tr v-if="filterValues.flags.includes('free_round')">
														<td>Free Rounds:</td>
														<td>
															{{
																item.data.global.free_round.avg_bet
																	| Number({
																		minimumFractionDigits: item.minor_units,
																		maximumFractionDigits: item.minor_units,
																		currency: item.currency || "EUR",
																	})
															}}
														</td>
													</tr>
												</table>
											</v-tooltip>
										</template>
										<template
											v-for="key of ['sum_bet', 'sum_win', 'netto']"
											#[`item.${key}`]="{ item }">
											<v-tooltip
												bottom
												:disabled="disabledFields.includes(key)"
												:key="key">
												<template v-slot:activator="{ on, attrs }">
													<div
														class="text-no-wrap"
														v-bind="attrs"
														v-on="on"
														:key="key"
														@click.stop.prevent="
															(e) => {
																disabledFields.includes(key)
																	? (showKProDialog = true)
																	: onCasinoRowClick(item, undefined, e);
															}
														"
														style="position: relative"
														:class="styleThis(key, item[key])">
														{{
															item[key]
																| Number({
																	minimumFractionDigits: item.minor_units,
																	maximumFractionDigits: item.minor_units,
																	currency: item.currency || "EUR",
																})
														}}
														<v-icon
															v-show="
																['sum_bet', 'sum_win'].includes(key) &&
																filterValues.flags.length > 0
															"
															size="15"
															class="warning--text pl-1"
															v-bind="attrs"
															v-on="on">
															mdi-information
														</v-icon>
													</div>
												</template>
												<table class="tooltipTable">
													<tr>
														<td>Classic:</td>
														<td>
															{{
																item.data.global.classic[key]
																	| Number({
																		minimumFractionDigits: item.minor_units,
																		maximumFractionDigits: item.minor_units,
																		currency: item.currency || "EUR",
																	})
															}}
														</td>
													</tr>
													<tr v-if="filterValues.flags.includes('free_round')">
														<td>Free Rounds:</td>
														<td>
															{{
																item.data.global.free_round[key]
																	| Number({
																		minimumFractionDigits: item.minor_units,
																		maximumFractionDigits: item.minor_units,
																		currency: item.currency || "EUR",
																	})
															}}
														</td>
													</tr>
													<tr
														v-if="
															['sum_win', 'netto'].includes(key) &&
															filterValues.flags.includes('joker_bonus')
														">
														<td>Joker Bonus:</td>
														<td>
															{{
																item.data.global.joker_bonus[key]
																	| Number({
																		minimumFractionDigits: item.minor_units,
																		maximumFractionDigits: item.minor_units,
																		currency: item.currency || "EUR",
																	})
															}}
														</td>
													</tr>
												</table>
											</v-tooltip>
										</template>
									</StatisticsTable>
									<!-- <div class="col-4 d-flex justify-end">
										<v-autocomplete multiple item-text="text" item-value="value" return-object :items="tableHeaders" v-model="selectedHeader"></v-autocomplete>
										<v-btn @click="testFunc">TEST</v-btn>
									</div> -->
								</v-card-text>
							</v-card>
						</v-slide-x-transition>
						<v-slide-y-transition
							group
							hide-on-leave>
							<template v-for="(tabDef, index) of tabs">
								<v-card
									v-show="isActive(index)"
									flat
									:key="`${tabDef.title}-${tabDef.count}`">
									<v-card-title class="ml-1 mb-1">
										<div class="d-flex flex-row align-center">
											<v-icon
												ref="tabCopy"
												class="mr-3"
												@click="onTabCopy(tabDef)">
												mdi-content-copy
											</v-icon>
											<div class="d-flex flex-column">
												<span class="primary--text">
													{{
														tabDef.filter?.casinos?.length === 1
															? `${casinoParse(tabDef.filter.casinos[0])}`
															: ""
													}}
													{{ tabDef.level == 4 ? `- ${tabDef.game.name}` : "" }}
												</span>
												{{ scope | Capitalize }} statistics -
												{{ timeToText(tabDef.filter) }}
											</div>
										</div>
										<v-spacer></v-spacer>
										<v-btn
											v-if="
												_usrFlagsSome({
													key: `statistic`,
													val: permissions.READ,
												})
											"
											elevation="1"
											fab
											small
											color="primary"
											@click="onExportOpen('statistics')">
											<v-icon>mdi-arrow-down-bold-circle</v-icon>
										</v-btn>
									</v-card-title>
									<v-card-text>
										<StatisticsTable
											:disabledFields="disabledFields"
											dataType="classic"
											:ref="`stats-${index}`"
											:headers="tabDef.headers"
											:loadList="tabDef.loadFn"
											:tabKey="tabDef.tabKey"
											:aggregator="tabDef.aggregator"
											:allowedCasinos="allowedCasinos"
											:loadParams="tabDef.loadParams"
											:filter="tabDef.filter"
											:showGraph="showGraph"
											:level="tabDef.level"
											:scope="scope"
											:graphLabels.sync="tabDef.graphLabels"
											:graphType.sync="tabDef.graphType"
											:_options.sync="tabDef.options"
											@openDetail="tabDef.onRowClick"
											@toggleGraph="toggleGraph"
											@addToCompare="tabDef.onCompare"
											@loading="handleLoading"
											:key="`${tabDef.headers.join()}-classic`">
											<template #[`item.rtp`]="{ item, extraData }">
												<v-tooltip
													:disabled="
														!tabDef.filter.flags.includes('joker_bonus') ||
														!_usrFlagsSome({
															key: 'statistic',
															val: permissions.READ,
														})
													"
													bottom>
													<template v-slot:activator="{ on, attrs }">
														<span
															:class="styleThis('rtp', item.rtp)"
															v-bind="attrs"
															v-on="on">
															{{ item.rtp | Percentage }}
														</span>
													</template>
													<table class="tooltipTable">
														<tr>
															<td>Total:</td>
															<td>
																{{ item.rtp | Percentage }}
															</td>
														</tr>
														<tr>
															<td>Rounds:</td>
															<td>
																{{
																	(wAvg(
																		item.sum_win,
																		item.sum_bet,
																		extraData.joker_bonus.find(
																			(el) =>
																				el[tabDef.headers[0].value] ==
																				item[tabDef.headers[0].value]
																		)?.sum_win,
																		false
																	) ?? 0) | Percentage
																}}
															</td>
														</tr>
														<tr
															v-if="
																tabDef.filter.flags.includes('joker_bonus')
															">
															<td>Joker Bonus:</td>
															<td>
																+{{
																	(wAvg(
																		item.sum_win,
																		item.sum_bet,
																		extraData.joker_bonus.find(
																			(el) =>
																				el[tabDef.headers[0].value] ==
																				item[tabDef.headers[0].value]
																		)?.sum_win
																	) ?? 0) | Percentage
																}}
															</td>
														</tr>
													</table>
												</v-tooltip>
											</template>
											<template #[`item.rounds_count`]="{ item, extraData }">
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<span
															v-bind="attrs"
															v-on="on">
															{{
																item.rounds_count
																	| Number({
																		style: "decimal",
																		minimumFractionDigits: 0,
																		maximumFractionDigits: 0,
																	})
															}}
														</span>
													</template>
													<table class="tooltipTable">
														<tr>
															<td>Classic:</td>
															<td>
																{{
																	extraData.classic.find(
																		(el) =>
																			el[tabDef.headers[0].value] ==
																			item[tabDef.headers[0].value]
																	)?.rounds_count
																		| Number({
																			style: "decimal",
																			minimumFractionDigits: 0,
																			maximumFractionDigits: 0,
																		})
																}}
															</td>
														</tr>
														<tr
															v-if="tabDef.filter.flags.includes('free_round')">
															<td>Free Rounds:</td>
															<td>
																{{
																	extraData.free_round.find(
																		(el) =>
																			el[tabDef.headers[0].value] ==
																			item[tabDef.headers[0].value]
																	)?.rounds_count
																		| Number({
																			style: "decimal",
																			minimumFractionDigits: 0,
																			maximumFractionDigits: 0,
																		})
																}}
															</td>
														</tr>
													</table>
												</v-tooltip>
											</template>
											<template #[`item.date_from`]="{ item }">
												{{ item.date_from | Date(dateFormat) }}
											</template>
											<template #[`item.date_to`]="{ item }">
												{{ item.date_to | Date }}
											</template>
											<template #[`item.avg_bet`]="{ item, extraData }">
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<div
															style="position: relative"
															:class="styleThis('avg_bet', item.avg_bet)"
															v-bind="attrs"
															v-on="on">
															{{
																item.avg_bet
																	| Number({
																		minimumFractionDigits: item.minor_units,
																		maximumFractionDigits: item.minor_units,
																		currency: item.currency || "EUR",
																	})
															}}
														</div>
													</template>
													<table class="tooltipTable">
														<tr>
															<td>Classic:</td>
															<td>
																{{
																	extraData.classic.find(
																		(el) =>
																			el[tabDef.headers[0].value] ==
																			item[tabDef.headers[0].value]
																	)?.avg_bet
																		| Number({
																			minimumFractionDigits: item.minor_units,
																			maximumFractionDigits: item.minor_units,
																			currency: item.currency || "EUR",
																		})
																}}
															</td>
														</tr>
														<tr
															v-if="tabDef.filter.flags.includes('free_round')">
															<td>Free Rounds:</td>
															<td>
																{{
																	extraData.free_round.find(
																		(el) =>
																			el[tabDef.headers[0].value] ==
																			item[tabDef.headers[0].value]
																	)?.avg_bet
																		| Number({
																			minimumFractionDigits: item.minor_units,
																			maximumFractionDigits: item.minor_units,
																			currency: item.currency || "EUR",
																		})
																}}
															</td>
														</tr>
													</table>
												</v-tooltip>
											</template>
											<template
												v-for="key of ['sum_bet', 'sum_win', 'netto']"
												#[`item.${key}`]="{ item, extraData }">
												<v-tooltip
													bottom
													:disabled="disabledFields.includes(key)"
													:key="key">
													<template v-slot:activator="{ on, attrs }">
														<div
															v-bind="attrs"
															class="text-no-wrap"
															style="position: relative"
															:class="styleThis(key, item[key])"
															v-on="on">
															{{
																item[key]
																	| Number({
																		minimumFractionDigits: item.minor_units,
																		maximumFractionDigits: item.minor_units,
																		currency: item.currency || "EUR",
																	})
															}}
															<v-icon
																v-show="
																	['sum_bet', 'sum_win'].includes(key) &&
																	tabDef.filter.flags.length > 0
																"
																size="15"
																class="warning--text pl-1"
																v-bind="attrs"
																v-on="on">
																mdi-information
															</v-icon>
														</div>
													</template>
													<table class="tooltipTable">
														<tr>
															<td>Classic:</td>
															<td>
																{{
																	extraData.classic.find(
																		(el) =>
																			el[tabDef.headers[0].value] ==
																			item[tabDef.headers[0].value]
																	)?.[key]
																		| Number({
																			minimumFractionDigits: item.minor_units,
																			maximumFractionDigits: item.minor_units,
																			currency: item.currency || "EUR",
																		})
																}}
															</td>
														</tr>
														<tr
															v-if="tabDef.filter.flags.includes('free_round')">
															<td>Free Rounds:</td>
															<td>
																{{
																	extraData.free_round.find(
																		(el) =>
																			el[tabDef.headers[0].value] ==
																			item[tabDef.headers[0].value]
																	)?.[key]
																		| Number({
																			minimumFractionDigits: item.minor_units,
																			maximumFractionDigits: item.minor_units,
																			currency: item.currency || "EUR",
																		})
																}}
															</td>
														</tr>
														<tr
															v-if="
																['sum_win', 'netto'].includes(key) &&
																tabDef.filter.flags.includes('joker_bonus')
															">
															<td>Joker Bonus:</td>
															<td>
																{{
																	extraData.joker_bonus.find(
																		(el) =>
																			el[tabDef.headers[0].value] ==
																			item[tabDef.headers[0].value]
																	)?.[key]
																		| Number({
																			minimumFractionDigits: item.minor_units,
																			maximumFractionDigits: item.minor_units,
																			currency: item.currency || "EUR",
																		})
																}}
															</td>
														</tr>
													</table>
												</v-tooltip>
											</template>
										</StatisticsTable>
									</v-card-text>
								</v-card>
							</template>
						</v-slide-y-transition>
					</div>
				</v-card>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col>
				<v-slide-y-transition>
					<CompareCard
						ref="noFunc"
						:loading="false"
						:items="compareItems"
						v-if="
							compareItems !== null &&
							compareItems !== undefined &&
							compareItems.length > 0
						"
						@closeAll="closeAllCompare"
						:rows="compareRows">
						<template #[`row.title.value`]="{ item, index }">
							<div class="d-flex justify-space-between">
								<div class="d-flex flex-column">
									<div
										v-for="(element, elIndex) of item.title.split(` @ `)"
										:key="element"
										class="text-truncate">
										{{ element }}
										<span v-if="elIndex == 0 && item.count > 1">
											( {{ item.count }} )
										</span>
									</div>
								</div>
								<div>
									<v-btn
										class="mb-1"
										text
										fab
										x-small
										color="error"
										@click.stop="removeCompareTab(index)">
										<v-icon>mdi-close</v-icon>
									</v-btn>
								</div>
							</div>
						</template>
						<template #[`row.rtp.value`]="{ item, diff }">
							<span>
								{{ item.rtp | Percentage }}
								<span
									v-if="diff !== 0"
									:class="{
										'error--text': diff < 0,
										'success--text': diff > 0,
									}">
									({{ diff > 0 ? "+" : "-"
									}}{{
										Math.abs(diff)
											| Number({
												style: "decimal",
												minimumFractionDigits: 3,
												maximumFractionDigits: 3,
											})
									}})
								</span>
							</span>
						</template>
						<template #[`row.rounds_count.value`]="{ item, diff }">
							<span>
								{{
									item.rounds_count
										| Number({
											style: "decimal",
											minimumFractionDigits: 0,
											maximumFractionDigits: 0,
										})
								}}
								<span
									v-if="diff !== 0"
									:class="{
										'error--text': diff < 0,
										'success--text': diff > 0,
									}">
									({{ diff > 0 ? "+" : "-"
									}}{{
										Math.abs(diff)
											| Number({
												style: "decimal",
												minimumFractionDigits: 0,
												maximumFractionDigits: 0,
											})
									}})
								</span>
							</span>
						</template>
						<template #[`row.currency.value`]="{ item }">
							{{ item.currency || "EUR" }}
						</template>
						<template #[`row.avg_bet.value`]="{ item, diff, items }">
							<span>
								{{
									item.avg_bet
										| Number({
											minimumFractionDigits: item.minor_units,
											maximumFractionDigits: item.minor_units,
											currency: item.currency || "EUR",
										})
								}}
								<template v-if="diff != 0">
									<span
										v-if="
											(item.currency || 'EUR') === (items[0].currency || 'EUR')
										"
										:class="{
											'error--text': diff < 0,
											'success--text': diff > 0,
										}">
										({{ diff > 0 ? "+" : "-"
										}}{{
											Math.abs(diff)
												| Number({
													minimumFractionDigits: item.minor_units,
													maximumFractionDigits: item.minor_units,
													currency: item.currency || "EUR",
												})
										}})
									</span>
									<v-tooltip
										v-else
										bottom
										color="warning darken-2">
										<template #activator="{ on }">
											<span
												class="warning--text"
												v-on="on">
												?
											</span>
										</template>
										<span>Currencies doesn't match</span>
									</v-tooltip>
								</template>
							</span>
						</template>

						<template
							v-for="key of ['sum_bet', 'sum_win', 'netto']"
							#[`row.${key}.value`]="{ item, diff, items }">
							<span :key="key">
								<div
									style="position: relative"
									class="ml-1"
									:class="styleThis(key)">
									{{
										item[key]
											| Number({
												minimumFractionDigits: item.minor_units,
												maximumFractionDigits: item.minor_units,
												currency: item.currency || "EUR",
											})
									}}
								</div>
								<template v-if="diff != 0">
									<span
										v-if="
											(item.currency || 'EUR') ===
												(items[0].currency || 'EUR') &&
											_usrFlagsSome({
												key: `statistic`,
												val: permissions.READ,
											})
										"
										:class="{
											'error--text': diff < 0,
											'success--text': diff > 0,
										}">
										({{ diff > 0 ? "+" : "-"
										}}{{
											Math.abs(diff)
												| Number({
													minimumFractionDigits: item.minor_units,
													maximumFractionDigits: item.minor_units,
													currency: item.currency || "EUR",
												})
										}})
									</span>
									<v-tooltip
										v-else-if="
											_usrFlagsSome({
												key: `statistic`,
												val: permissions.READ,
											})
										"
										bottom
										color="warning darken-2">
										<template #activator="{ on }">
											<span
												class="warning--text"
												v-on="on">
												?
											</span>
										</template>
										<span>Currencies doesn't match</span>
									</v-tooltip>
								</template>
							</span>
						</template>
					</CompareCard>
				</v-slide-y-transition>
			</v-col>
		</v-row>
		<v-dialog
			@click:outside="exportClose"
			@keydown.esc="exportClose"
			max-width="500px"
			v-model="exportDialog">
			<ExportDialog
				@close="exportClose"
				:builder="statsType"
				showSettings
				:data="statsExportData"
				:filename="filename"
				:settingItems="[
					{ name: 'Classic', id: 'classic' },
					{
						name: 'Special',
						id: 'special',
						children: [
							{ name: 'Free Rounds', id: 'free_round' },
							{ name: 'Joker Bonus', id: 'joker_bonus' },
						],
					},
				]"
				ref="statsExport">
				<template
					v-if="exportDialog"
					#settings="{ settingItems, changeModel }">
					<h2 class="d-flex flex-row justify-center mt-n10">
						Select statistics to export
					</h2>

					<div class="d-flex flex-row ml-8 mt-5 justify-space-around">
						<div
							class="d-flex mr-9 mt-5"
							v-for="(tree, index) in settingItems"
							:key="tree.name">
							<v-divider
								v-if="index > 0"
								vertical></v-divider>
							<v-treeview
								selected-color="primary"
								:open="['special']"
								@input="(e) => changeModel(e, tree.id)"
								selectable
								:items="[tree]"></v-treeview>
						</div>
					</div>
					<div class="mt-10">
						<div class="d-flex flex-row align-center">
							<v-checkbox
								v-model="exportCategories"
								class="mr-2 mt-0 mb-0"
								hide-details></v-checkbox>
							Export Categories
						</div>
						<div class="d-flex flex-row align-center">
							<v-checkbox
								v-model="exportRemoved"
								class="mr-2 mt-0"
								hide-details></v-checkbox>
							Export Removed Games
						</div>
					</div>
				</template>
			</ExportDialog>
		</v-dialog>
		<v-snackbar
			style="position: fixed; bottom: 20px; text-align: center"
			content-class="kajot-text--text"
			app
			transition="slide-y-reverse-transition"
			color="menu_background"
			:timeout="snackTimeout"
			v-model="comparionSnack">
			<template #action>
				<v-btn
					small
					@click="scrollToCompare"
					class="mr-5"
					text
					color="primary">
					Scroll to
				</v-btn>
			</template>
			<div class="d-flex align-center justify-center">
				<v-icon
					class="mr-5"
					color="kajot-text">
					mdi-arrow-down-bold
				</v-icon>
				<span class="ml-4">Added to comparison window</span>
			</div>
		</v-snackbar>
		<v-dialog
			width="500px"
			v-model="showKProDialog">
			<v-card>
				<v-card-title>
					<div class="d-flex justify-center">
						<v-img
							v-if="$vuetify.theme.dark"
							width="250px"
							src="../../kpluswhite.png"></v-img>
					</div>
					<div class="d-flex justify-center">
						<v-img
							v-if="!$vuetify.theme.dark"
							width="250px"
							src="../../kplusblack.png"></v-img>
					</div>
				</v-card-title>
				<v-card-text>
					<div class="d-flex flex-row justify-center mb-3">
						Purchase
						<span class="font-weight-bold mx-1">
							KAJOT
							<span style="color: #fecb4d">PLUS</span>
						</span>
						to display casino data.
					</div>
					<div
						style="
							border-radius: 25px;
							border: 2px solid #fecb4d;
							padding: 35px;
						">
						<div class="font-weight-bold text-h5">Solo</div>
						<div>For small time analyst dudes.</div>
						<div class="font-weight-bold text-h4 my-4">$269</div>
						<div class="d-flex flex-row">
							<v-icon
								class="mr-2"
								color="#fecb4d"
								size="16">
								mdi-check-circle
							</v-icon>
							<div>One analyst</div>
						</div>
						<div class="d-flex flex-row">
							<v-icon
								class="mr-2"
								color="#fecb4d"
								size="16">
								mdi-check-circle
							</v-icon>
							<div>Unlimited casinos</div>
						</div>
						<div class="d-flex flex-row">
							<v-icon
								class="mr-2"
								color="#fecb4d"
								size="16">
								mdi-check-circle
							</v-icon>
							<div>Better yellow design</div>
						</div>
						<div class="d-flex flex-row">
							<v-icon
								class="mr-2"
								color="#fecb4d"
								size="16">
								mdi-check-circle
							</v-icon>
							<div>Unlimited minor & patch updates</div>
						</div>
						<div class="d-flex flex-row mb-8">
							<v-icon
								class="mr-2"
								color="#fecb4d"
								size="16">
								mdi-check-circle
							</v-icon>
							<div>Lifetime access</div>
						</div>
						<v-btn
							class="black--text"
							color="#FECB4D">
							Buy Now
						</v-btn>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import { constants, ROUND_FLAGS } from "../../constants/constants";
import { StatisticsConverter } from "../../constants/converters/statisticsConverter";
import localstorage from "../../mixins/localstorage";
import CompareCard from "../shared/CompareCard.vue";
import ExportDialog from "../shared/ExportDialog.vue";
import FilterSideBar from "../shared/FilterSideBar.vue";
import StatisticsSumForm from "./StatisticsSumForm.vue";
import StatisticsTable from "./StatisticsTable.vue";
import { isMobile } from "../../constants/helpers";
import permissions from "../../mixins/permissions";
import { generateHash } from "../../constants/helpers";

//CHARTJS
import {
	ArcElement,
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Colors,
	Legend,
	LineElement,
	LinearScale,
	LogarithmicScale,
	PointElement,
	Title,
	Tooltip,
} from "chart.js";
import {
	casinoChanged,
	isNullish,
	objFromZlib,
	objToZlib,
} from "../../constants/helpers";
ChartJS.register(
	Title,
	Tooltip,
	Legend,
	BarElement,
	CategoryScale,
	LinearScale,
	ArcElement,
	LogarithmicScale,
	PointElement,
	LineElement,
	Colors
);

export default {
	mixins: [localstorage, permissions],
	filters: {
		Capitalize: function (value) {
			return value.charAt(0).toUpperCase() + value.slice(1);
		},
		stats: function (value) {
			return `${value} Statistics`;
		},
	},
	props: {
		scope: {
			type: String,
			default: "general",
		},
	},
	components: {
		StatisticsSumForm,
		FilterSideBar,
		StatisticsTable,
		CompareCard,
		ExportDialog,
	},
	watch: {
		currentCasino(v, vThen) {
			this.defaultFilter.casinos = [v];
			if (isNullish(v)) return;
			this.filter.casinos = casinoChanged(
				vThen,
				v,
				this.filter?.casinos ?? [v]
			);
			this.onFilterUpdate(this.filter);
		},
	},
	async mounted() {
		const query = new URLSearchParams(window.location.search);
		if (query.has("tab")) {
			await new Promise((resolve, reject) => {
				const checkCondition = () => {
					if (!this.tableLoading) {
						resolve();
					} else {
						setTimeout(checkCondition, 100);
					}
				};
				checkCondition();
			});
			this.tab = 1;
		}
		await this.$nextTick();
		this.tabClick(this.tab - 1, true);
	},
	async created() {
		this.disabledFields = this._usrFlagsSome({
			key: "statistic",
			val: this.permissions.READ,
		})
			? []
			: ["sum_bet", "sum_win", "netto"];
		const query = new URLSearchParams(window.location.search);
		if (query.size > 0) {
			this.$router.replace({
				path: this.$router.currentRoute.path,
				query: {},
			});
		}

		this.$syncAndAssign(
			this.$route.name,
			"showGraph",
			"options",
			"graphLabels",
			"graphType",
			"tabFuckingLator"
		);

		this.defaultFilter = { ...this.filter };

		this.defaultFilter.casinos = [this.currentCasino];

		this.$syncWithStorage(["currentCasino", "filter"], this.$route.name);

		let tmpFilter;
		if (query.has("home")) {
			try {
				const tmpHome = objFromZlib(query.get("home"));
				tmpFilter = tmpHome.filter;
				this.graphType = tmpHome.graphType ?? this.graphType;
				this.graphLabels = tmpHome.graphLabels ?? this.graphLabels;
			} catch (error) {
				console.error("Filter was present but undefined");
			}
		} else {
			tmpFilter = this.$getFromStorage("filter");
		}

		this.previousCasino = this.$getFromStorage("currentCasino");

		if (tmpFilter) {
			this.filter = tmpFilter;
		}

		this.filter.casinos = casinoChanged(
			this.previousCasino,
			this.currentCasino,
			this.filter?.casinos
		);

		if (this.scope === "general") {
			this.defaultFilter.dates = [];
		} else {
			this.defaultFilter.dates = [
				(() => {
					const date = new Date();
					let len = 10;
					if (this.scope === "monthly") {
						len = 7;
						date.setUTCDate(1);
					}
					date.setUTCHours(0, 0, 0, 0);
					return `${date.toISOString().substring(0, len)}`;
				})(),
			];
		}
		if (tmpFilter === null) {
			this.filter = { ...this.defaultFilter };
		} else {
			this.filter = tmpFilter;
		}
		let tempTabs;
		this.$syncWithStorage("tabs", this.$route.name);
		if (query.has("filter")) {
			return;
		}
		if (query.has("tab")) {
			const tmpTab = query.get("tab");
			try {
				let parsedData = objFromZlib(tmpTab);
				tempTabs = [parsedData];
			} catch (error) {
				console.error("Tab was present but undefined");
			}
		} else {
			tempTabs = this.$getFromStorage("tabs");
		}
		if (!Array.isArray(tempTabs)) return;
		tempTabs?.forEach((el) => {
			if (el.level === 2) {
				this.tabs.push({
					...el,
					onRowClick: (e2, f, ev) =>
						this.onGameRowClick(e2, f, el.casino, el.title, ev),
					onCompare: (e2) =>
						this.compareItems.push({
							...e2,
							count: el.count,
							title: `${el.title} @ ${e2.name}`,
						}),
					loadFn: (e, r) => this._loadGames(e, el.tabKey, r),
				});
			} else if (el.level === 4) {
				this.tabs.push({
					...el,
					onRowClick: () => {},
					onCompare: (e2) =>
						this.compareItems.push({
							...e2,
							count: el.count,
							title: `${el.title} (${e2.currency})`,
						}),
					loadFn: (e, r) =>
						this._loadCurrencies(el.game, el.filter, el.tabKey, r),
				});
			} else if (el.level === 1) {
				this.tabs.push({
					...el,
					onRowClick: (e2, f, ev) =>
						this.onTimeRangeRowClick(e2, f, el.item, ev),
					onCompare: (e2, filter) => {
						let date_to = this.getDateTo(e2.date_from, filter);
						const DF = this.$options.filters.Date;
						const f = {
							dateStyle: "short",
							timeStyle: "short",
							timeZoneName: undefined,
						};
						this.compareItems.push({
							...e2,
							count: el.count,
							title: `${el.title} @ ${DF(e2.date_from, f)} @ ${DF(date_to, f)}`,
						});
					},
					loadFn: (e, r) => this._loadDates(el.filter, el.tabKey, r),
				});
			}
		});
	},
	data() {
		return {
			showKProDialog: false,
			disabledFields: [],
			statsType: "statistics",

			copied: false,
			graphLabels: undefined,
			graphType: undefined,
			previousCasino: undefined,

			exportCategories: false,
			exportRemoved: false,

			showGraph: [0],
			comparionSnack: false,
			snackTimeout: 3000,
			round_flags: ROUND_FLAGS,
			globalize: StatisticsConverter.globalize,
			tableLoading: true,
			compareItems: [],
			settings: [],
			options: {},
			filename: "testExport",
			defaultFilter: {},
			filter: {
				dates: [],
				times: [],
				games: [],
				casinos: [],
				categories: [],
				currencies: [],
				flags: ["free_round", "joker_bonus"],
			},
			exportTypes: [],
			specialType: undefined,
			rootParams: undefined,
			tabs: [],
			tabFuckingLator: 0,
			noOptions: true,
			perCasinoHeaders: [
				{
					text: "Casino",
					value: "name",
					align: "left",
					sortable: false,
					isData: false,
				},
				{
					text: "Date to",
					value: "date_to",
					align: "left",
					isData: false,
					hide: () => this.scope !== "general",
				},
				{ text: "Σ Bet", value: "sum_bet", align: "right", isData: true },
				{ text: "Σ Win", value: "sum_win", align: "right", isData: true },
				{ text: "Netto", value: "netto", align: "right", isData: true },
				{ text: "Rtp", value: "rtp", align: "right", isData: true },
				{ text: "Avg Bet", value: "avg_bet", align: "right", isData: true },
				{
					text: "Round Count",
					value: "rounds_count",
					align: "right",
					isData: true,
				},
				{
					text: "Actions",
					value: "actions",
					align: "center",
					sortable: false,
					isData: false,
				},
			],
			mainHeaders: [
				{ text: "Date from", value: "date_from", align: "left", isData: false },
				{
					text: "Date to",
					value: "date_to",
					align: "left",
					isData: false,
					hide: () => this.scope !== "general",
				},
				{ text: "Σ Bet", value: "sum_bet", align: "right", isData: true },
				{ text: "Σ Win", value: "sum_win", align: "right", isData: true },
				{ text: "Netto", value: "netto", align: "right", isData: true },
				{ text: "Rtp", value: "rtp", align: "right", isData: true },
				{ text: "Avg Bet", value: "avg_bet", align: "right", isData: true },
				{
					text: "Round Count",
					value: "rounds_count",
					align: "right",
					isData: true,
				},
				{
					text: "Actions",
					value: "actions",
					align: "right",
					sortable: false,
					isData: false,
				},
			],
			GameHeaders: [
				{ text: "Game name", value: "name", align: "left", isData: false },
				{ text: "Σ Bet", value: "sum_bet", align: "right", isData: true },
				{ text: "Σ Win", value: "sum_win", align: "right", isData: true },
				{ text: "Netto", value: "netto", align: "right", isData: true },
				{ text: "Rtp", value: "rtp", align: "right", isData: true },
				{ text: "Avg Bet", value: "avg_bet", align: "right", isData: true },
				{
					text: "Round Count",
					value: "rounds_count",
					align: "right",
					isData: true,
				},
				{
					text: "Actions",
					value: "actions",
					align: "right",
					sortable: false,
					isData: false,
				},
			],
			typeHeaders: [
				{ text: "Currency", value: "currency", align: "center", isData: false },
				{ text: "Σ Bet", value: "sum_bet", align: "right", isData: true },
				{ text: "Σ Win", value: "sum_win", align: "right", isData: true },
				{ text: "Netto", value: "netto", align: "right", isData: true },
				{ text: "Rtp", value: "rtp", align: "right", isData: true },
				{ text: "Avg Bet", value: "avg_bet", align: "right", isData: true },
				{
					text: "Round Count",
					value: "rounds_count",
					align: "right",
					isData: true,
				},
				{
					text: "Actions",
					value: "actions",
					align: "center",
					sortable: false,
					isData: false,
				},
			],
			gameDetailHeaders: [
				{ text: "Currency", value: "currency", align: "center", isData: false },
				{ text: "Σ Bet", value: "sum_bet", align: "right", isData: true },
				{ text: "Σ Win", value: "sum_win", align: "right", isData: true },
				{ text: "Netto", value: "netto", align: "right", isData: true },
				{ text: "Rtp", value: "rtp", align: "right", isData: true },
				{ text: "Avg Bet", value: "avg_bet", align: "right", isData: true },
				{
					text: "Round Count",
					value: "rounds_count",
					align: "right",
					isData: true,
				},
				{
					text: "Actions",
					value: "actions",
					align: "center",
					sortable: false,
					isData: false,
				},
			],
			compareRows: [
				{ label: "", key: "title" },
				{ label: "Currency", key: "currency" },
				{ label: "Σ Bet", key: "sum_bet" },
				{ label: "Σ Win", key: "sum_win" },
				{ label: "Netto", key: "netto" },
				{ label: "Rtp", key: "rtp" },
				{ label: "Avg Bet", key: "avg_bet" },
				{ label: "Round Count", key: "rounds_count" },
			],
			filterValues: {},
			draggingIndex: -1,
			dragDepth: 0,
			lastParent: null,
			lastEntered: null,
			exportDialog: false,
		};
	},
	computed: {
		isMobile,
		tab: {
			get() {
				return this.tabFuckingLator;
			},
			set(val) {
				if (val > this.tabLength) {
					this.tabFuckingLator = 0;
				} else {
					this.tabFuckingLator = isNaN(val) ? 0 : val;
				}
			},
		},
		tabLength() {
			return this.tabs.length;
		},
		dateFormat() {
			switch (this.scope) {
				case "daily":
					return {
						day: "2-digit",
						month: "2-digit",
						year: "numeric",
						timeZoneName: undefined,
					};
				case "monthly":
					return { month: "long", year: "numeric", timeZoneName: undefined };
				default:
					return {};
			}
		},
		statsExportFilename() {
			return "testExport";
		},
		statsExportData() {
			const tabIndex = this.tab - 1;
			const res = {
				url: this.workerUrl,
				authToken: this.authToken,
				exportTypes: this.exportTypes,
				specialType: this.specialType,
				casinos: this.allowedCasinos,
				allowedGames: this.allowedGames,
				CTCMap: this.casinoCategoryMap,
				C2CMap: this.categoryCasinoMap,
				cExport: this.exportCategories,
				rExport: this.exportRemoved,
				scope: this.scope,
				filter: this.tabs[tabIndex]?.filter ?? this.filterValues,
				depth: this.tabs[tabIndex]?.level ?? 0,
				timeRange: this.tabs[tabIndex]?.timeRange,
				game: this.tabs[tabIndex]?.game,
				tab: this.tab,
			};
			return [
				[
					["game_name", "Game"],
					["game_technical", "Technical"],
					["flag", "Flag"],
					["sum_bet", "Σ Bet"],
					["sum_win", "Σ Win"],
					["netto", "Netto"],
					["rounds_count", "Rounds Count"],
					["currency", "Currency"],
					["casino_id", "Casino"],
					["casino_client", "Client Name"],
					["date", "Date"],
				],
				res,
			];
		},
		tabHeight() {
			if (this.tabs.length == 0) return 1;

			return this.tabs.reduce((prev, next) => {
				prev = Math.max(next.title.split(" @ ").length, prev);
				return prev;
			}, 0);
		},
		filterIsEmpty() {
			return _.isEqual(
				this.backendifyFilter(this.filter),
				this.backendifyFilter(this.defaultFilter)
			);
		},
		filterIsActive() {
			return _.isEqual(this.backendifyFilter(this.filter), this.statsFilter);
		},
		...mapGetters({
			currentCasino: "currentCasino",
			allowedCasinos: "allowedCasinos",
			casinoCategoryMap: "casinoCategoryMap",
			categoryCasinoMap: "categoryCasinoMap",
			workerUrl: "loganUrl",
			userPerm: "userPerm",
		}),
		...mapGetters("meta2", { allowedGames: "allowedGames" }),
		...mapGetters("apiCall", { authToken: "accessToken" }),
		...mapGetters("statistics2", {
			statsFilter: "filter",
		}),
	},
	methods: {
		refreshCurrentTab() {
			const currentTab =
				this.tabFuckingLator - 1 >= 0 ? this.tabFuckingLator - 1 : "home";
			const currentTabElement = Array.isArray(this.$refs[`stats-${currentTab}`])
				? this.$refs[`stats-${currentTab}`]
				: [this.$refs[`stats-${currentTab}`]];
			currentTabElement[0].loadData(true);
		},
		onCopy(obj, key) {
			const b64 = objToZlib(obj);

			const url = new URL(
				`${window.location.origin}${window.location.pathname}`
			);
			url.searchParams.set(key, b64);
			navigator.clipboard.writeText(url);
		},
		onCopyHome() {
			this.copied = true;
			this.onCopy(
				{
					filter: this.filter,
					graphLabels: this.graphLabels,
					graphType: this.graphType,
				},
				"home"
			);
		},
		onTabCopy(def) {
			this.copied = true;
			this.onCopy(def, "tab");
		},
		styleThis(key, val) {
			return `${this[`${key}Style`]?.(val)} ${this.hiddenStyle(key)}`;
		},
		nettoStyle(netto) {
			if (netto < 0) return "rtp-red--text font-weight-medium";
		},
		rtpStyle(rtp) {
			switch (true) {
				case (rtp > 0 && rtp <= 0.85) || rtp > 1.05:
					return "rtp-red--text font-weight-medium";
				case (rtp > 0.85 && rtp <= 0.9) || (rtp > 1.0 && rtp <= 1.05):
					return "rtp-orange--text font-weight-medium";
			}
		},
		hiddenStyle(key) {
			if (!this.disabledFields.includes(key)) return;
			// if (key === "sum_bet" && this.disabledFields.includes("sum_bet")) {
			// 	return "k-hidden";
			// }
			if (this.disabledFields.includes(key)) {
				return "k-hidden";
			}
		},
		toggleGraph(e) {
			this.showGraph = e;
		},
		async closeAllTabs() {
			const length = this.tabs.length;
			for (let i = 1; i <= length; i++) {
				await this.removeTab(this.tabs.length - i);
			}
		},
		async tabClick(index, force = false) {
			if (force || !this.tableLoading) {
				this.tab = index + 1;

				await this.$nextTick();

				if (this.tab === 0) {
					this.$refs[`stats-home`].loadData(!this.$refs[`stats-home`].loaded);
				} else {
					this.$refs[`stats-${index}`][0].loadData();
				}
			}
		},
		closeAllCompare() {
			this.compareItems = [];
		},
		scrollToCompare() {
			this.$refs.noFunc.$el.scrollIntoView({ behavior: "smooth" });
		},
		wAvg(win, bet, jbWin = 0, diff = true) {
			const rtp = win / bet;
			const noJbRtp = (win - jbWin) / bet;
			if (!diff) {
				return noJbRtp;
			}
			return rtp - noJbRtp;
		},
		casinoParse(id) {
			return `${
				this.allowedCasinos.find((el) => el.casino_id === id)?.website
			}`;
		},
		handleLoading(e) {
			this.tableLoading = e;
		},
		timeToText(filter) {
			if (filter.date_from === undefined && filter.date_to === undefined)
				return "All time";
			const from = filter.date_from
				? new Date(filter.date_from).toLocaleDateString(navigator.language, {
						timeZone: "UTC",
				  })
				: "";
			if (filter.date_to === undefined) return `after ${from}`;
			const to = filter.date_to
				? new Date(new Date(filter.date_to).getTime() - 1).toLocaleDateString(
						navigator.language,
						{
							timeZone: "UTC",
						}
				  )
				: "";
			return `${from} - ${to}`;
		},
		exportClose(e) {
			this.exportDialog = false;
			this.exportCategories = false;
			this.exportRemoved = false;
			this.$refs.statsExport.onClose();
		},
		async onExportOpen(type = "statistics") {
			this.statsType = type;
			await this.$nextTick();
			(this.filename = `stats-${new Date().toISOString()}`),
				(this.exportDialog = true);
		},
		onClickExport(exportTypes, specialType) {
			this.exportTypes = exportTypes;
			this.specialType = specialType;
			this.exportDialog = true;
			//v-dialog internally uses v-if directive, we need to wait for a bit for ref to be registered
			setTimeout(() => {
				this.$refs.statsExport.onExportOpen();
			}, 500);
		},
		isActive(a) {
			return a + 1 == this.tab;
		},
		onClear() {
			this.filter = { ...this.defaultFilter };
			this.onFilterUpdate(this.filter);
		},
		async removeCompareTab(index) {
			await this.$refs.noFunc.hide(index);
			this.compareItems.splice(index, 1);
		},
		/**
		 *
		 * @param {DragEvent} e
		 */
		async dragStart(e) {
			/**
			 * @type {HTMLElement}
			 */

			const target = e.target;
			this.tab = parseInt(target.dataset.index) + 1;

			const path = e.composedPath();
			const parent = path?.find((el) => el.dataset.index !== undefined);
			parent?.classList?.add("dragged");
			e.dataTransfer.setData("index", target.dataset.index);
			e.dataTransfer.dropEffect = "move";
			this.draggingIndex = target.dataset.index;
			this.dragDepth = 0;
			await this.$nextTick();
			parent?.classList?.add("dragged");
		},
		dragEnd(e) {
			const path = e.composedPath();
			const parent = path?.find((el) => el.dataset.index !== undefined);
			parent?.classList?.remove("dragged");
		},
		/**
		 *
		 * @param {DragEvent} e
		 */
		dragOver(e, index) {
			e.preventDefault();
		},
		/**
		 *
		 * @param {DragEvent} e
		 */
		dragEnter(e, index) {
			this.dragDepth++;
			this.lastEntered = index;
			const path = e.composedPath();
			if (
				index != null &&
				index != undefined &&
				this.draggingIndex != undefined &&
				index != this.draggingIndex
			) {
				const parent = path?.find((el) => el.dataset.index !== undefined);
				parent?.classList?.add("drag");
				if (parent !== this.lastParent) {
					this.lastParent?.classList?.remove("drag");
				}
			} else if (index == this.draggingIndex) {
				this.lastParent?.classList?.remove("drag");
			}
		},
		dragLeave(e, index) {
			this.dragDepth--;
			const path = e.composedPath();
			this.lastParent = path?.find((el) => el.dataset.index !== undefined);
			if (this.dragDepth < 1 || this.lastEntered !== index) {
				this.lastParent?.classList?.remove("drag");
			}
		},
		async onDrop(e, index) {
			const draggedIndex = e.dataTransfer.getData("index");
			if (index == this.draggingIndex) return;
			[this.tabs[draggedIndex], this.tabs[index]] = [
				this.tabs[index],
				this.tabs[draggedIndex],
			];
			this.tab = index + 1;

			this.lastParent?.classList?.remove("drag");
		},
		createSnacc() {
			this.comparionSnack = true;
			this.snackTimeout = this.snackTimeout === 3000 ? 3001 : 3000;
		},
		onCasinoCompare(e2) {
			this.createSnacc();
			let date_to = this.getDateTo(e2.date_from, this.filterValues);
			const DF = this.$options.filters.Date;
			const f = {
				dateStyle: "short",
				timeStyle: "short",
				timeZoneName: undefined,
			};
			this.compareItems.push({
				...e2,
				count: this.tabs.filter((el) => el.title === e2.name).length + 1,
				title: `${e2.name} @ ${DF(e2.date_from, f)} @ ${DF(e2.date_to, f)}`,
			});
		},
		async onCasinoRowClick(item, _, ev) {
			if (this.scope == "general") {
				this.onTimeRangeRowClick(item, this.filterValues, item, ev);
				return;
			}
			const title = item.name;
			const count = this.tabs.filter((el) => el.title === title).length + 1;
			const tabKey = generateHash();
			this.tabs.push({
				title,
				count,
				tabKey,
				aggregator: "date_from",
				item,
				level: 1,
				options: {},
				headers: this.mainHeaders,
				filter: {
					...this.filterValues,
					casinos:
						item.id == "all" ? [...this.filterValues?.casinos] : [item.id],
				},
				onRowClick: (e2, f, ev) => this.onTimeRangeRowClick(e2, f, item, ev),
				onCompare: (e2, filter) => {
					this.createSnacc();
					let date_to = this.getDateTo(e2.date_from, filter);
					const DF = this.$options.filters.Date;
					const f = {
						dateStyle: "short",
						timeStyle: "short",
						timeZoneName: undefined,
					};
					this.compareItems.push({
						...e2,
						count,
						title: `${title} @ ${DF(e2.date_from, f)} @ ${DF(date_to, f)}`,
					});
				},
				loadFn: (e, r) =>
					this._loadDates(
						{
							...this.filterValues,
							casinos:
								item.id == "all" ? [...this.filterValues?.casinos] : [item.id],
						},
						tabKey,
						r
					),
			});
			if (!ev.ctrlKey && !ev.metaKey) {
				this.tab = this.tabs.length;
				await this.$nextTick();
				this.$refs[`stats-${this.tab - 1}`][0].loadData();
			}
		},
		getDateTo(date_from, filter) {
			let date_to = new Date(date_from);
			switch (this.scope) {
				case "general": {
					if (filter.date_to) {
						date_to = new Date(filter.date_to);
					} else {
						date_to = undefined
					}
					break;
				}
				case "monthly": {
					date_to.setMonth(date_to.getMonth() + 1);
					break;
				}
				case "daily": {
					date_to.setDate(date_to.getDate() + 1);
					break;
				}
			}
			return date_to;
		},
		createTimeRangeTitle(item, filter, casino) {
			let date_to = this.getDateTo(item.date_from, filter);
			const DF = this.$options.filters.Date;
			const f = {
				dateStyle: "short",
				timeStyle: "short",
				timeZoneName: undefined,
			};
			var title
			if(!date_to){
				title = `${casino.name} @ from ${DF(item.date_from, f)}`;
			} else {
				title = `${casino.name} @ ${DF(item.date_from, f)} @ ${DF(
					date_to,
					f
				)}`;
			}
			return { title, date_to };
		},
		async onTimeRangeRowClick(item, filter, casino, ev) {
			console.log(item);
			const { title, date_to } = this.createTimeRangeTitle(
				item,
				filter,
				casino
			);

			const count = this.tabs.filter((el) => el.title === title).length + 1;
			const tabKey = generateHash();
			this.tabs.push({
				title,
				count,
				tabKey,
				aggregator: "technical",
				timeRange: item,
				options: { sortBy: ["sum_bet"], sortDesc: [true] },
				level: 2,
				headers: this.GameHeaders,
				filter: {
					...filter,
					date_from: item.date_from ?? filter.date_from,
					date_to: date_to?.toISOString(),
					casinos: casino.id === "all" ? filter.casinos : [casino.id],
				},
				onRowClick: (e2, f, ev) =>
					this.onGameRowClick(e2, f, casino, title, ev),

				onCompare: (e2) => {
					this.createSnacc();
					this.compareItems.push({
						...e2,
						count,
						title: `${title} @ ${e2.name}`,
					});
				},
				loadFn: (e, r) => this._loadGames(e, tabKey, r),
			});
			if (!ev.ctrlKey && !ev.metaKey) {
				this.tab = this.tabs.length;
				await this.$nextTick();
				this.$refs[`stats-${this.tab - 1}`][0].loadData();
			}
		},
		async onGameRowClick(item, filter, _casino, parentTitle, ev) {
			const title = `${parentTitle} @ ${item.name}`;
			const count = this.tabs.filter((el) => el.title === title).length + 1;
			const tabKey = generateHash();
			this.tabs.push({
				title,
				count,
				tabKey,
				aggregator: "currency",
				game: item,
				filter,
				options: {},
				level: 4,
				prefix: item.name,
				headers: this.gameDetailHeaders,
				onRowClick: () => {},
				onCompare: (e2) => {
					this.createSnacc();
					this.compareItems.push({
						...e2,
						count,
						title: `${title} (${e2.currency})`,
					});
				},
				loadFn: (e, r) => this._loadCurrencies(item, filter, tabKey, r),
			});

			if (!ev.ctrlKey && !ev.metaKey) {
				this.tab = this.tabs.length;
				await this.$nextTick();
				this.$refs[`stats-${this.tab - 1}`][0].loadData();
			}
		},
		...mapActions("statistics2", {
			loadStats2: "loadScopeList",
			loadGames: "loadGameList",
			loadGameCurrencies: "loadGameCurrencyList",
		}),
		async removeTab(index) {
			this.$refs[`tab-${index}`]?.[0]?.classList?.add("closing");
			setTimeout(async () => {
				//IF SELF
				if (index + 1 == this.tab) {
					if (this.tabs.length == index + 1) {
						this.tab--;
					}
				} else {
					if (index + 1 < this.tab) {
						this.tab--;
					}
				}
				this.tabs.splice(index, 1);
				await this.$nextTick();
				this.$refs[`stats-${this.tab - 1}`]?.[0].loadData();
			}, 300);
		},
		async _loadPerCasino(val, retry) {
			const v = JSON.parse(JSON.stringify(val));
			if (_.isEqual(v, {})) return [];
			return await this.loadStats2({ ...val, tabKey: "rootTab", retry });
		},
		async _loadGames(val, tabKey, retry) {
			return await this.loadGames({ payload: val, tabKey, retry });
		},
		async _loadDates(val, tabKey, retry) {
			return await this.loadStats2({
				scope: this.scope,
				payload: val,
				tabKey,
				retry,
			});
		},
		async _loadCurrencies(item, filter, tabKey, retry) {
			return await this.loadGameCurrencies({
				gameId: item.technical,
				payload: {
					...filter,
					games: undefined,
				},
				tabKey,
				retry,
			});
		},
		backendifyFilter(val) {
			const sortedTemp = [...val?.dates].sort(
				(a, b) => new Date(a) - new Date(b)
			);
			const sortedDates = sortedTemp.map(
				(el, idx) =>
					new Date(`${el}${val.times[idx] ? "T" + val.times[idx] + "Z" : ""}`)
			);
			if (sortedDates.length > 1) {
				switch (this.scope) {
					case "daily": {
						sortedDates[1].setDate(sortedDates[1].getDate() + 1);
						break;
					}
					case "monthly": {
						sortedDates[1].setMonth(sortedDates[1].getMonth() + 1);
						break;
					}
					case "general": {
						if (!val?.times?.[1]) {
							sortedDates[1].setDate(sortedDates[1].getDate() + 1);
						}
						break;
					}
				}
			}
			const isoDates = sortedDates?.map((date) => date.toISOString());
			return {
				currencies: val.currencies,
				casinos: val.casinos,
				categories: val.categories,
				games: val.games,
				date_from: isoDates[0],
				date_to: isoDates[1],
				flags: [...val.flags].sort(),
			};
		},
		async onFilterUpdate(val) {
			this.filterValues = this.backendifyFilter(val);

			this.rootParams = {
				scope: this.scope,
				payload: this.filterValues,
				selectedTab: this.tabFuckingLator,
			};
		},
	},
};
</script>

<style scoped>
::v-deep .v-treeview-node__toggle {
	visibility: hidden;
}

.k-hidden {
	white-space: nowrap;
}

.k-very-hidden {
	pointer-events: none;
}

.k-hidden::after {
	content: "";
	position: absolute;
	width: 110%;
	height: 130%;
	top: -15%;
	left: -5%;
	border-radius: 10px;
	display: block;
	backdrop-filter: blur(3px);
}
</style>

<style>
.sliderElementWrapper {
	flex: 1 1 10%;
	position: relative;
	overflow: hidden;
}

table.tooltipTable td:nth-child(1) {
	text-align: start;
	padding-right: 5px;
}

table.tooltipTable td:nth-child(2) {
	text-align: end;
}

.sliderElement {
	width: 100%;
	text-align: center;
	font-size: small;
}

.sliderElement.active {
	color: var(--v-primary-base);
}

.sliderElement.active::before {
	background-color: var(--v-primary-base);
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 2px;
}

.sliderElement::after {
	background-color: currentColor;
	pointer-events: none;
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	opacity: 0;
	transition: all 0.3s;
}

.sliderElement.active::after {
	opacity: 0.3;
}

.sliderElement:hover:not(.active)::after {
	opacity: 0.1;
}

.closing .sliderElementClose {
	visibility: hidden;
}

.drag {
	background-color: var(--v-primary-base);
	color: var(--v-kajot-text-base) !important;
}

.closing {
	animation: closing 0.3s ease-out 0s 1 normal forwards;
	overflow: hidden;
}

.v-tooltip__content {
	opacity: 1;
	color: var(--v-kajot-text-base);
	background: var(--v-background-base);
}

@keyframes closing {
	to {
		max-width: 0%;
	}
}

.dragged {
	opacity: 0.6;
	z-index: 100;
	border: 2px dashed var(--v-primary-base);
}
</style>
