<template>
	<v-card
		:tile="tile"
		:outlined="outlined"
		:flat="flat"
		class="v-card--material px-5 py-3">
		<template v-if="!loading">
			<slot name="heading">
				<card-heading
					v-if="title"
					:color="color"
					:title="title"></card-heading>
			</slot>
			<v-card-text
				class="overflow-x-auto"
				:class="stretch">
				<template v-for="(row, rowIndex) of rows">
					<v-slide-x-transition :key="`${row.key}-${rowIndex}`">
						<v-row
							dense
							:key="`${row.key}-${rowIndex}`"
							class="flex-nowrap">
							<v-col
								class="highlight align-center"
								:cols="2">
								<v-btn
									@click="$emit('closeAll')"
									class="error--text"
									v-if="rowIndex === 0"
									text>
									Close all
								</v-btn>
								<v-list-item v-else>
									<v-list-item-content class="text-body-1">
										<slot
											:name="`row.${row.key}.label`"
											:row="row">
											{{ row.label }}
										</slot>
									</v-list-item-content>
								</v-list-item>
							</v-col>
							<v-col
								:cols="_cols"
								class="highlight"
								v-for="(item, itemIndex) of items"
								:key="`${row.key}--${rowIndex}-${itemIndex}`">
								<v-list-item>
									<v-list-item-content class="text-body-1">
										<slot
											:name="`row.${row.key}.value`"
											:row="row"
											:items="items"
											:diff="item[row.key] - items[0][row.key]"
											:item="item"
											:index="itemIndex">
											{{ item[row.key] }}
										</slot>
									</v-list-item-content>
								</v-list-item>
							</v-col>
						</v-row>
					</v-slide-x-transition>
					<v-divider
						:key="`divider${rowIndex}`"
						v-if="rowIndex < rows.length - 1"></v-divider>
				</template>
			</v-card-text>
		</template>
	</v-card>
</template>
<script>
import CardHeading from "./CardHeading.vue";

export default {
	components: { CardHeading },
	props: {
		items: Array,
		rows: Array,
		loading: { type: Boolean, default: true },
		tile: { type: Boolean, default: false },
		outlined: { type: Boolean, default: false },
		flat: { type: Boolean, default: false },
		color: { type: String, default: "secondary" },
		stretch: { type: String, default: "" },
		title: { type: String, default: undefined },
	},
	data() {
		return {
			hideTimeout: 0,
		};
	},
	computed: {
		_cols() {
			return Math.max(2, 10 / this.items.length);
		},
	},
	methods: {
		hide(index) {
			return new Promise((res) => {
				setTimeout(res, this.hideTimeout);
			});
		},
	},
};
</script>
<style>
.highlight:nth-child(even) {
	background-color: var(--v-kajot-highlight-row-base);
}
.theme--light .highlight:nth-child(even) {
	mix-blend-mode: darken;
}
.theme--dark .highlight:nth-child(even) {
	mix-blend-mode: lighten;
}
</style>
