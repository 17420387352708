<template>
	<v-container class="pt-0 mt-0">
		<v-row
			class="pt-0 mt-0"
			pa-2>
			<v-col
				cols="12"
				class="py-0 my-0 d-flex justify-center">
				<v-date-picker
					class="mt-4"
					v-model="_filter.dates"
					color="primary"
					header-color="defaultbg"
					full-width
					range
					flat
					:title-date-format="dateFormatter"
					:type="pickerType"></v-date-picker>
			</v-col>
			<div
				style="width: 100%"
				class="d-flex flex-row justify-end">
				<v-btn
					@click="
						(e) => {
							_filter.dates = [];
							_filter.times = [];
						}
					"
					text
					class="error--text">
					Clear
				</v-btn>
			</div>
			<v-row
				class="pa-0 ma-0"
				v-if="scope === 'general'">
				<v-col
					class="py-0 my-0"
					cols="6">
					<v-menu
						ref="menu1"
						v-model="menu1"
						:close-on-content-click="false"
						:nudge-right="20"
						transition="scale-transition"
						offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="_filter.times[0]"
								label="Time from"
								:disabled="!_filter.dates[0]"
								prepend-icon="mdi-clock-time-four-outline"
								clearable
								readonly
								v-bind="attrs"
								v-on="on"></v-text-field>
						</template>
						<v-time-picker
							class="ma-0"
							v-if="menu1"
							format="24hr"
							v-model="_filter.times[0]"
							@click:minute="
								$refs.menu1.save(_filter.times[2])
							"></v-time-picker>
					</v-menu>
				</v-col>
				<v-col
					class="py-0 my-0"
					cols="6">
					<v-menu
						ref="menu2"
						v-model="menu2"
						:close-on-content-click="false"
						:nudge-left="150"
						transition="scale-transition"
						offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="_filter.times[1]"
								clearable
								:disabled="!_filter.dates[1]"
								label="Time to"
								prepend-icon="mdi-clock-time-four-outline"
								readonly
								v-bind="attrs"
								v-on="on"></v-text-field>
						</template>
						<v-time-picker
							class="ma-0"
							v-if="menu2"
							format="24hr"
							v-model="_filter.times[1]"
							@click:minute="
								$refs.menu2.save(_filter.times[1])
							"></v-time-picker>
					</v-menu>
				</v-col>
			</v-row>
			<v-col class="py-0">
				<EditComponent
					noDialog
					v-model="_filter.currencies"
					:fullArray="currencyCodes"
					:loading="gamesLoading"
					:width="500"
					title="Edit Currency List"
					placeholderText="Currencies"
					color="primary"></EditComponent>

				<EditComponent
					noDialog
					v-model="_filter.games"
					:fullArray="
						[...games].sort((a, b) => (a.technical > b.technical ? 1 : -1))
					"
					:loading="gamesLoading"
					valueKey="technical"
					labelKey="game_name"
					:width="500"
					title="Edit Games List"
					placeholderText="Games"
					color="primary">
					<template #appendItem="{ item }">
						<v-chip
							small
							v-if="item.removed">
							Removed
						</v-chip>
					</template>
				</EditComponent>

				<v-autocomplete
					multiple
					clearable
					deletable-chips
					small-chips
					label="Categories"
					v-model="categories"
					:items="allowedCategories">
					<template #item="{ item, on, attrs }">
						<v-list-item
							:disabled="categoryItemCount(item) === 0"
							v-on="on"
							v-bind="attrs">
							<v-list-item-content>
								<v-list-item-title>
									<div>{{ item }}</div>
								</v-list-item-title>
								<v-spacer></v-spacer>
								<v-list-item-subtitle>
									<v-chip
										small
										:color="`${categories.includes(item) ? 'success' : ''}`">
										{{ inCasinosCount(item) }}/{{ categoryItemCount(item) }}
									</v-chip>
								</v-list-item-subtitle>
							</v-list-item-content>
							<v-list-item-action>
								<v-row>
									<v-btn
										@click.stop.prevent="addCategoryCasinos(item)"
										class="mr-2 elevation-0"
										fab
										color="primary"
										width="20"
										height="20">
										<v-icon>mdi-plus</v-icon>
									</v-btn>
									<v-btn
										@click.stop.prevent="removeCategoryCasinos(item)"
										class="mr-1 elevation-0"
										color="primary"
										fab
										width="20"
										height="20">
										<v-icon>mdi-minus</v-icon>
									</v-btn>
								</v-row>
							</v-list-item-action>
						</v-list-item>
					</template>
				</v-autocomplete>

				<EditComponent
					noDialog
					v-model="_filter.casinos"
					:key="`${filteredCasinos.length}`"
					:fullArray="filteredCasinos"
					:loading="false"
					:searchIn="[`casino_id`]"
					valueKey="casino_id"
					labelKey="website"
					:width="500"
					title="Edit Casino List"
					placeholderText="Casinos"
					color="primary">
					<template #appendItem="{ item }">
						<v-chip small>ID: {{ item.casino_id }}</v-chip>
					</template>
				</EditComponent>
				<v-row class="d-flex justify-center mt-2">
					<v-checkbox
						multiple
						v-model="_filter.flags"
						value="joker_bonus"
						inset
						class="mt-0 mr-3">
						<template v-slot:label>
							<span class="ml-3">Joker Bonus</span>
						</template>
					</v-checkbox>
					<v-checkbox
						multiple
						v-model="_filter.flags"
						value="free_round"
						inset
						class="mt-0">
						<template v-slot:label>
							<span class="ml-3">Free Rounds</span>
						</template>
					</v-checkbox>
				</v-row>
				<div class="d-flex justify-center mt-3">
					<v-btn
						@click="manualFilter"
						:disabled="loading || filterIsActive"
						:loading="loading"
						class="primary">
						Apply
					</v-btn>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import localstorage from "../../mixins/localstorage";
import { currencyCodes } from "../../constants/currencyCodes";
import EditComponent from "../../components/jackpots/EditComponent.vue";
import _ from "lodash";

export default {
	props: {
		scope: {
			type: String,
			default: "general",
		},
		autoFilter: {
			type: Boolean,
			default: false,
		},
		filterIsActive: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		filter: {
			type: Object,
			default: () => ({
				dates: [],
				times: [],
				games: [],
				casinos: [],
				currencies: [],
				flags: ["free_round", "joker_bonus"],
			}),
		},
	},
	mixins: [localstorage],
	components: { EditComponent },
	model: {
		prop: "filter",
		event: "filter",
	},
	data() {
		return {
			menu1: false,
			menu2: false,
			currencyCodes,
			gamesLoading: false,
			casinosLoading: false,
		};
	},
	computed: {
		categories: {
			get() {
				return this._filter.categories ?? [];
			},
			set(e) {
				this._filter.categories = e;
			},
		},
		filteredCasinos() {
			if (this.categories.length === 0) {
				return this.allowedCasinos;
			}
			const cass = _.intersection(
				...this.categories.reduce((agg, curr) => {
					agg.push(
						this.casinoCategoryMap.get(curr)
							? this.casinoCategoryMap.get(curr).map((el) => el.casino_id)
							: []
					);
					return agg;
				}, [])
			);
			return this.allowedCasinos.filter((el) => cass.includes(el.casino_id));
		},
		_filter: {
			get() {
				return this.filter;
			},
			set(val) {
				this.$emit("filter", { ...val, ...this._filter });
			},
		},
		...mapGetters({
			_currentCasino: "currentCasino",
		}),
		currentCasino() {
			return this.allowedCasinos?.find(
				(el) => el.casino_id === this._currentCasino
			);
		},
		...mapGetters("meta2", {
			games: "allowedGames",
		}),
		...mapGetters({
			allowedCasinos: "allowedCasinos",
			allowedCategories: "allowedCategories",
			casinoCategoryMap: "casinoCategoryMap",
			categoryCasinoMap: "categoryCasinoMap",
		}),
		pickerType() {
			return this.scope === "monthly" ? "month" : "date";
		},
	},
	methods: {
		addCategoryCasinos(item) {
			const categoryCasinos = (this.casinoCategoryMap.get(item) ?? []).map(
				(el) => el.casino_id
			);
			const casinos = this._filter.casinos ?? [];
			this._filter.casinos = Array.from(
				new Set([...categoryCasinos, ...casinos])
			);
		},
		removeCategoryCasinos(item) {
			const categoryCasinos = (this.casinoCategoryMap.get(item) ?? []).map(
				(el) => el.casino_id
			);
			const casinos = this._filter.casinos ?? [];
			this._filter.casinos = [
				...casinos.filter((el) => !categoryCasinos.includes(el)),
			];
		},
		categoryItemCount(item) {
			if (this.categories.length < 1) {
				return this.casinoCategoryMap.get(item).length;
			} else {
				const casInCat = this.categories.map(
					(el) => this.casinoCategoryMap.get(el) ?? []
				);
				return _.intersection(this.casinoCategoryMap.get(item), ...casInCat)
					.length;
			}
		},
		inCasinosCount(item) {
			return _.intersection(
				this.casinoCategoryMap.get(item).map((el) => el.casino_id),
				this._filter.casinos
			).length;
		},

		async manualFilter() {
			await this.$nextTick();
			this.$emit("update", this._filter);
		},
		removeElement(index, from) {
			from.splice(index, 1);
		},
		toggleAllGames() {
			this.$nextTick(() => {
				if (this._filter.games.length === this.games.length) {
					this._filter.games = [];
				} else {
					this._filter.games = [...this.games];
				}
			});
		},
		toggleAllCasinos() {
			this.$nextTick(() => {
				if (this._filter.casinos.length === this.allowedCasinos.length) {
					this._filter.casinos = [];
				} else {
					this._filter.casinos = [...this.allowedCasinos];
				}
			});
		},
		toggleAllCurrencies() {
			this.$nextTick(() => {
				if (this._filter.currencies.length === this.currencyCodes.length) {
					this._filter.currencies = [];
				} else {
					this._filter.currencies = [...this.currencyCodes];
				}
			});
		},
		...mapActions("meta2", {
			loadStoreCasinos: "loadCasinos",
		}),
		async loadCasinos() {
			this.casinosLoading = true;
			await this.loadStoreCasinos();
			this.casinosLoading = false;
		},

		dateFormatter(val) {
			if (val.length === 0) return "Select from";
			if (val.length === 1) return "Select to";
			if (val.length === 2) return val.join(" - ");
		},
	},

	watch: {
		categories: {
			handler(newV, oldV) {
				if (_.isEqual(_.sortBy(newV), _.sortBy(oldV))) return;
				const cass = newV.reduce((agg, curr) => {
					agg.push(
						this.casinoCategoryMap.get(curr)
							? this.casinoCategoryMap.get(curr).map((el) => el.casino_id)
							: []
					);
					return agg;
				}, []);
				this._filter.casinos = _.intersection(...cass);
			},
			deep: true,
		},
		watch: {
			currentCasino(v) {
				if (v) this._filter.casinos = [v.casino_id];
				if (!this.autoFilter) this.$emit("update", this._filter);
			},
		},
	},

	created() {
		this.$emit("update", this._filter);
	},
};
</script>
<style scoped>
::v-deep .v-date-picker-title {
	color: var(--v-kajot-text-base) !important;
}
</style>
