var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-data-table", {
        attrs: {
          loading: _vm.dataLoading,
          loadingText: _vm.loadingText,
          headers: _vm.filteredHeaders,
          itemClass: _vm.itemClass,
          options: _vm.__options,
          items: _vm.data,
        },
        on: {
          "update:options": function ($event) {
            _vm.__options = $event
          },
          "click:row": (item, _, e) => {
            if (_vm.level < 3) _vm.$emit("openDetail", item, _vm.filter, e)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "progress",
              fn: function () {
                return [
                  _c("v-progress-linear", {
                    attrs: { value: _vm.progress, color: "primary" },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "top",
              fn: function ({ pagination }) {
                return [
                  _c("v-data-footer", {
                    staticClass: "anton",
                    attrs: { options: _vm.__options, pagination: pagination },
                    on: {
                      "update:options": function ($event) {
                        _vm.__options = $event
                      },
                    },
                  }),
                ]
              },
            },
            {
              key: `header.${_vm.filteredHeaders[0].value}`,
              fn: function () {
                return [
                  _vm.failedPartial(_vm.tabKey)?.length > 0
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "primary--text",
                          attrs: { text: "", fab: "", small: "" },
                          on: {
                            click: function ($event) {
                              _vm.failDialog = true
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-refresh-circle")])],
                        1
                      )
                    : _vm._e(),
                  _c("span", [
                    _vm._v(" " + _vm._s(_vm.filteredHeaders[0].text) + " "),
                  ]),
                ]
              },
              proxy: true,
            },
            _vm._l(_vm.$scopedSlots, function (_, name) {
              return {
                key: name,
                fn: function (slotProps) {
                  return [
                    _vm._t(name, null, { extraData: _vm.extraData }, slotProps),
                  ]
                },
              }
            }),
            {
              key: `item.actions`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on: tooltip }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "mx-2",
                                      attrs: {
                                        fab: "",
                                        "x-small": "",
                                        text: "",
                                        outlined: "",
                                        color: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.$emit(
                                            "addToCompare",
                                            item,
                                            _vm.filter
                                          )
                                        },
                                      },
                                    },
                                    tooltip
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-compare-horizontal"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_c("span", [_vm._v("Add to comparison")])]
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c(
        "v-expansion-panels",
        {
          staticClass: "ma-0 pa-0",
          style: `z-index: ${_vm.fullScreen ? 200 : 1}`,
          attrs: { flat: "", value: 0, multiple: "" },
          model: {
            value: _vm._showGraph,
            callback: function ($$v) {
              _vm._showGraph = $$v
            },
            expression: "_showGraph",
          },
        },
        [
          _c(
            "v-expansion-panel",
            { staticClass: "ma-0 pa-0" },
            [
              _c(
                "v-expansion-panel-header",
                {
                  staticClass: "my-0 py-0 my-4",
                  staticStyle: { "min-height": "40px" },
                  attrs: {
                    ripple: "",
                    color: `menu_background ${
                      _vm.$vuetify.theme.dark ? "" : "lighten-1"
                    }`,
                  },
                },
                [_c("b", [_vm._v("Graph")])]
              ),
              _c(
                "v-expansion-panel-content",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row justify-space-between align-center",
                    },
                    [
                      _c(
                        "v-sheet",
                        {
                          staticClass: "pa-1",
                          class: { sfscreenSelector: _vm.fullScreen },
                          attrs: {
                            width: _vm.enabledGraphs.length * 45,
                            rounded: "",
                            color: `${
                              _vm.fullScreen ? "transparent" : "secondary"
                            }`,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "d-flex flex-row sliderBitch",
                              style: `background-position: ${_vm.sliderPos}; background-size: ${_vm.sliderSize}`,
                            },
                            _vm._l(_vm.enabledGraphs, function (el) {
                              return _c(
                                "div",
                                {
                                  key: el,
                                  staticClass: "px-2 py-1",
                                  staticStyle: { "border-radius": "5px" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "noHover",
                                      attrs: {
                                        color: `${
                                          _vm.graphType == el
                                            ? "kajot-anti-text"
                                            : "kajot-text"
                                        }`,
                                      },
                                      on: { click: (e) => _vm.switchGraph(el) },
                                    },
                                    [_vm._v(" mdi-" + _vm._s(el) + " ")]
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex flex-row justify-end",
                          class: { sfscreenOff: _vm.fullScreen },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "noHover mr-1",
                              attrs: { color: "kajot-text" },
                              on: { click: _vm.downloadGraph },
                            },
                            [_vm._v(" mdi-download ")]
                          ),
                          _c("a", {
                            ref: "exportLink",
                            attrs: {
                              download: `graph.png`,
                              href: _vm.graphUrl,
                              hidden: "",
                            },
                          }),
                          _c(
                            "v-icon",
                            {
                              staticClass: "noHover",
                              attrs: { color: "kajot-text" },
                              on: {
                                click: function ($event) {
                                  _vm.fullScreen = !_vm.fullScreen
                                },
                              },
                            },
                            [
                              _vm._v(
                                " mdi-fullscreen" +
                                  _vm._s(_vm.fullScreen ? "-exit" : "") +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "w100 d-flex justify-center mb-4" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row flex-wrap justify-center mt-1",
                        },
                        [
                          _vm._l(
                            _vm.pieLegend.filter(
                              (el) =>
                                !_vm.disabledFields.includes(
                                  _vm.filteredHeaders.find(
                                    (fh) => fh.text === el.text
                                  ).value
                                )
                            ),
                            function (item) {
                              return [
                                _c(
                                  "v-chip",
                                  {
                                    key: item.text,
                                    staticClass: "k-reset noTouch mr-1 mt-1",
                                    attrs: {
                                      small: "",
                                      color: `${
                                        item.hidden ||
                                        (_vm.isSpecial &&
                                          _vm.selectedLine !== item.value)
                                          ? "gray"
                                          : item.fillStyle
                                      }`,
                                    },
                                    on: {
                                      click: (e) => _vm.legendClick(e, item),
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.text) + " ")]
                                ),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ]
                  ),
                  _c("v-overlay", {
                    staticStyle: { "z-index": "900" },
                    attrs: {
                      value: _vm.fullScreen,
                      persistent: "",
                      color: "nav_background",
                      opacity: "0.7",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "sf",
                      class: [
                        { sfscreen: _vm.fullScreen },
                        "d-flex justify-center",
                      ],
                      staticStyle: { height: "85vh" },
                      style: `position: ${_vm.fullScreen ? "fixed" : "sticky"}`,
                    },
                    [
                      !_vm.getDrawerLoading && _vm.data?.length > 0
                        ? _c(_vm.graphType, {
                            ref: "graph",
                            tag: "Component",
                            attrs: {
                              data:
                                _vm.graphType == "chart-pie"
                                  ? _vm.pieData
                                  : _vm.chartData,
                              options:
                                _vm.graphType == "chart-pie"
                                  ? _vm.pieOptions
                                  : _vm.chartOptions,
                              plugins: _vm.chartPlugins,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.failDialog,
            callback: function ($$v) {
              _vm.failDialog = $$v
            },
            expression: "failDialog",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { dense: "" } },
            [
              _c(
                "v-card-title",
                {
                  staticClass:
                    "primary kajot-anti-text--text d-flex align-center justify-center pa-2",
                  staticStyle: { "font-weight": "500" },
                },
                [_vm._v(" Data loading failed ")]
              ),
              _c("FailedItems", {
                attrs: {
                  casinoList: _vm.casinoList,
                  unfinished: _vm.failedPartial(this.tabKey),
                },
                model: {
                  value: _vm.retry,
                  callback: function ($$v) {
                    _vm.retry = $$v
                  },
                  expression: "retry",
                },
              }),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.failDialog = false
                        },
                      },
                    },
                    [_vm._v(" Close ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.retry.length === 0,
                        text: "",
                        color: "primary",
                      },
                      on: { click: _vm.onRetry },
                    },
                    [_vm._v(" Retry "), _c("v-icon", [_vm._v("mdi-refresh")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }