var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pt-0 mt-0" },
    [
      _c(
        "v-row",
        { staticClass: "pt-0 mt-0", attrs: { "pa-2": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "py-0 my-0 d-flex justify-center",
              attrs: { cols: "12" },
            },
            [
              _c("v-date-picker", {
                staticClass: "mt-4",
                attrs: {
                  color: "primary",
                  "header-color": "defaultbg",
                  "full-width": "",
                  range: "",
                  flat: "",
                  "title-date-format": _vm.dateFormatter,
                  type: _vm.pickerType,
                },
                model: {
                  value: _vm._filter.dates,
                  callback: function ($$v) {
                    _vm.$set(_vm._filter, "dates", $$v)
                  },
                  expression: "_filter.dates",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "d-flex flex-row justify-end",
              staticStyle: { width: "100%" },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "error--text",
                  attrs: { text: "" },
                  on: {
                    click: (e) => {
                      _vm._filter.dates = []
                      _vm._filter.times = []
                    },
                  },
                },
                [_vm._v(" Clear ")]
              ),
            ],
            1
          ),
          _vm.scope === "general"
            ? _c(
                "v-row",
                { staticClass: "pa-0 ma-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0 my-0", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-menu",
                        {
                          ref: "menu1",
                          attrs: {
                            "close-on-content-click": false,
                            "nudge-right": 20,
                            transition: "scale-transition",
                            "offset-y": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-text-field",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              label: "Time from",
                                              disabled: !_vm._filter.dates[0],
                                              "prepend-icon":
                                                "mdi-clock-time-four-outline",
                                              clearable: "",
                                              readonly: "",
                                            },
                                            model: {
                                              value: _vm._filter.times[0],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm._filter.times,
                                                  0,
                                                  $$v
                                                )
                                              },
                                              expression: "_filter.times[0]",
                                            },
                                          },
                                          "v-text-field",
                                          attrs,
                                          false
                                        ),
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4257471782
                          ),
                          model: {
                            value: _vm.menu1,
                            callback: function ($$v) {
                              _vm.menu1 = $$v
                            },
                            expression: "menu1",
                          },
                        },
                        [
                          _vm.menu1
                            ? _c("v-time-picker", {
                                staticClass: "ma-0",
                                attrs: { format: "24hr" },
                                on: {
                                  "click:minute": function ($event) {
                                    return _vm.$refs.menu1.save(
                                      _vm._filter.times[2]
                                    )
                                  },
                                },
                                model: {
                                  value: _vm._filter.times[0],
                                  callback: function ($$v) {
                                    _vm.$set(_vm._filter.times, 0, $$v)
                                  },
                                  expression: "_filter.times[0]",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0 my-0", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-menu",
                        {
                          ref: "menu2",
                          attrs: {
                            "close-on-content-click": false,
                            "nudge-left": 150,
                            transition: "scale-transition",
                            "offset-y": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-text-field",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              clearable: "",
                                              disabled: !_vm._filter.dates[1],
                                              label: "Time to",
                                              "prepend-icon":
                                                "mdi-clock-time-four-outline",
                                              readonly: "",
                                            },
                                            model: {
                                              value: _vm._filter.times[1],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm._filter.times,
                                                  1,
                                                  $$v
                                                )
                                              },
                                              expression: "_filter.times[1]",
                                            },
                                          },
                                          "v-text-field",
                                          attrs,
                                          false
                                        ),
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3998884907
                          ),
                          model: {
                            value: _vm.menu2,
                            callback: function ($$v) {
                              _vm.menu2 = $$v
                            },
                            expression: "menu2",
                          },
                        },
                        [
                          _vm.menu2
                            ? _c("v-time-picker", {
                                staticClass: "ma-0",
                                attrs: { format: "24hr" },
                                on: {
                                  "click:minute": function ($event) {
                                    return _vm.$refs.menu2.save(
                                      _vm._filter.times[1]
                                    )
                                  },
                                },
                                model: {
                                  value: _vm._filter.times[1],
                                  callback: function ($$v) {
                                    _vm.$set(_vm._filter.times, 1, $$v)
                                  },
                                  expression: "_filter.times[1]",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { staticClass: "py-0" },
            [
              _c("EditComponent", {
                attrs: {
                  noDialog: "",
                  fullArray: _vm.currencyCodes,
                  loading: _vm.gamesLoading,
                  width: 500,
                  title: "Edit Currency List",
                  placeholderText: "Currencies",
                  color: "primary",
                },
                model: {
                  value: _vm._filter.currencies,
                  callback: function ($$v) {
                    _vm.$set(_vm._filter, "currencies", $$v)
                  },
                  expression: "_filter.currencies",
                },
              }),
              _c("EditComponent", {
                attrs: {
                  noDialog: "",
                  fullArray: [..._vm.games].sort((a, b) =>
                    a.technical > b.technical ? 1 : -1
                  ),
                  loading: _vm.gamesLoading,
                  valueKey: "technical",
                  labelKey: "game_name",
                  width: 500,
                  title: "Edit Games List",
                  placeholderText: "Games",
                  color: "primary",
                },
                scopedSlots: _vm._u([
                  {
                    key: "appendItem",
                    fn: function ({ item }) {
                      return [
                        item.removed
                          ? _c("v-chip", { attrs: { small: "" } }, [
                              _vm._v(" Removed "),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm._filter.games,
                  callback: function ($$v) {
                    _vm.$set(_vm._filter, "games", $$v)
                  },
                  expression: "_filter.games",
                },
              }),
              _c("v-autocomplete", {
                attrs: {
                  multiple: "",
                  clearable: "",
                  "deletable-chips": "",
                  "small-chips": "",
                  label: "Categories",
                  items: _vm.allowedCategories,
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function ({ item, on, attrs }) {
                      return [
                        _c(
                          "v-list-item",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  disabled: _vm.categoryItemCount(item) === 0,
                                },
                              },
                              "v-list-item",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _c("div", [_vm._v(_vm._s(item))]),
                                ]),
                                _c("v-spacer"),
                                _c(
                                  "v-list-item-subtitle",
                                  [
                                    _c(
                                      "v-chip",
                                      {
                                        attrs: {
                                          small: "",
                                          color: `${
                                            _vm.categories.includes(item)
                                              ? "success"
                                              : ""
                                          }`,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.inCasinosCount(item)) +
                                            "/" +
                                            _vm._s(
                                              _vm.categoryItemCount(item)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-action",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mr-2 elevation-0",
                                        attrs: {
                                          fab: "",
                                          color: "primary",
                                          width: "20",
                                          height: "20",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.addCategoryCasinos(item)
                                          },
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("mdi-plus")])],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mr-1 elevation-0",
                                        attrs: {
                                          color: "primary",
                                          fab: "",
                                          width: "20",
                                          height: "20",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.removeCategoryCasinos(
                                              item
                                            )
                                          },
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("mdi-minus")])],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.categories,
                  callback: function ($$v) {
                    _vm.categories = $$v
                  },
                  expression: "categories",
                },
              }),
              _c("EditComponent", {
                key: `${_vm.filteredCasinos.length}`,
                attrs: {
                  noDialog: "",
                  fullArray: _vm.filteredCasinos,
                  loading: false,
                  searchIn: [`casino_id`],
                  valueKey: "casino_id",
                  labelKey: "website",
                  width: 500,
                  title: "Edit Casino List",
                  placeholderText: "Casinos",
                  color: "primary",
                },
                scopedSlots: _vm._u([
                  {
                    key: "appendItem",
                    fn: function ({ item }) {
                      return [
                        _c("v-chip", { attrs: { small: "" } }, [
                          _vm._v("ID: " + _vm._s(item.casino_id)),
                        ]),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm._filter.casinos,
                  callback: function ($$v) {
                    _vm.$set(_vm._filter, "casinos", $$v)
                  },
                  expression: "_filter.casinos",
                },
              }),
              _c(
                "v-row",
                { staticClass: "d-flex justify-center mt-2" },
                [
                  _c("v-checkbox", {
                    staticClass: "mt-0 mr-3",
                    attrs: { multiple: "", value: "joker_bonus", inset: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "ml-3" }, [
                              _vm._v("Joker Bonus"),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm._filter.flags,
                      callback: function ($$v) {
                        _vm.$set(_vm._filter, "flags", $$v)
                      },
                      expression: "_filter.flags",
                    },
                  }),
                  _c("v-checkbox", {
                    staticClass: "mt-0",
                    attrs: { multiple: "", value: "free_round", inset: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "ml-3" }, [
                              _vm._v("Free Rounds"),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm._filter.flags,
                      callback: function ($$v) {
                        _vm.$set(_vm._filter, "flags", $$v)
                      },
                      expression: "_filter.flags",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-center mt-3" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary",
                      attrs: {
                        disabled: _vm.loading || _vm.filterIsActive,
                        loading: _vm.loading,
                      },
                      on: { click: _vm.manualFilter },
                    },
                    [_vm._v(" Apply ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }