var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { id: "Statistics", fluid: "", tag: "section" } },
    [
      _c(
        "FilterSideBar",
        {
          attrs: {
            refreshButton: "",
            refreshText: "Reload Data",
            width: _vm.isMobile ? "" : "400px",
            loading: _vm.tableLoading,
            filterIsEmpty: _vm.filterIsEmpty,
            filterIsActive: _vm.filterIsActive,
          },
          on: { clear: _vm.onClear, refresh: _vm.refreshCurrentTab },
        },
        [
          _c("StatisticsSumForm", {
            ref: "filter",
            attrs: {
              filterIsActive: _vm.filterIsActive,
              loading: _vm.tableLoading,
              scope: _vm.scope,
            },
            on: { update: _vm.onFilterUpdate },
            model: {
              value: _vm.filter,
              callback: function ($$v) {
                _vm.filter = $$v
              },
              expression: "filter",
            },
          }),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          staticStyle: {
            position: "fixed",
            bottom: "20px",
            "text-align": "center",
          },
          attrs: {
            app: "",
            transition: "slide-y-reverse-transition",
            timeout: 1000,
            color: "menu_background",
            "content-class": "kajot-text--text",
          },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { fab: "", text: "", "x-small": "" },
                      on: {
                        click: function ($event) {
                          _vm.copied = false
                        },
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { attrs: { color: "kajot-text", small: "" } },
                        [_vm._v(" mdi-close ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.copied,
            callback: function ($$v) {
              _vm.copied = $$v
            },
            expression: "copied",
          },
        },
        [
          _c(
            "div",
            { staticClass: "align-center" },
            [
              _c("v-icon", { attrs: { color: "info" } }, [
                _vm._v("mdi-information"),
              ]),
              _vm._v(" Tab URL copied to clipboard. "),
            ],
            1
          ),
        ]
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c("v-card", { staticClass: "mt-0" }, [
                _c(
                  "div",
                  { staticClass: "d-flex flex-row flex-wrap" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "sliderElementWrapper",
                        on: {
                          click: () => {
                            if (!_vm.tableLoading) _vm.tabClick(-1)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              { name: "ripple", rawName: "v-ripple" },
                            ],
                            staticClass:
                              "sliderElement py-2 text-truncate d-flex justify-center align-center",
                            class: { active: _vm.isActive(-1) },
                            style: {
                              minHeight: _vm.tabHeight * 20 + "px",
                              height: "100%",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "align-center",
                                    attrs: {
                                      color: _vm.isActive(-1) ? "primary" : "",
                                    },
                                  },
                                  [_vm._v(" mdi-home ")]
                                ),
                                this.tabs.length > 0
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "error--text",
                                        staticStyle: { "z-index": "2" },
                                        attrs: { text: "" },
                                        on: { click: _vm.closeAllTabs },
                                      },
                                      [_vm._v(" Close all ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._l(_vm.tabs, function (tabDef, index) {
                      return _c(
                        "div",
                        {
                          directives: [{ name: "ripple", rawName: "v-ripple" }],
                          key: `${tabDef.title}-${index}-${tabDef.count}`,
                          ref: `tab-${index}`,
                          refInFor: true,
                          staticClass: "sliderElementWrapper d-flex flex-row",
                          class: { active: _vm.isActive(index) },
                          style: {
                            minHeight: _vm.tabHeight * 20 + "px",
                            maxWidth:
                              _vm.tabs.length >= 10
                                ? "10%"
                                : 100 / _vm.tabs.length + "%",
                          },
                          attrs: {
                            draggable: !_vm.tableLoading,
                            "data-index": index,
                          },
                          on: {
                            dragover: (e) => _vm.dragOver(e, index),
                            dragenter: (e) => _vm.dragEnter(e, index),
                            dragleave: (e) => _vm.dragLeave(e, index),
                            dragstart: _vm.dragStart,
                            dragend: (e) => _vm.dragEnd(e, index),
                            drop: (e) => _vm.onDrop(e, index),
                            click: () => {
                              _vm.tabClick(index)
                            },
                          },
                        },
                        [
                          _c(
                            "v-tooltip",
                            {
                              staticClass: "red",
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        tabDef.filter.flags.length > 0
                                          ? _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mt-1 ml-1",
                                                    staticStyle: {
                                                      "z-index": "1",
                                                    },
                                                    attrs: {
                                                      color: "warning",
                                                      size: "20px",
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" mdi-information ")]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "span",
                                [
                                  _c("tr", [_vm._v("Active Flags:")]),
                                  _vm._l(tabDef.filter.flags, function (flag) {
                                    return _c("tr", { key: flag }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.round_flags[flag].tooltip
                                          ) +
                                          " "
                                      ),
                                    ])
                                  }),
                                ],
                                2
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "sliderElement py-2 text-truncate d-flex flex-column justify-center align-center pl-1",
                              class: { active: _vm.isActive(index) },
                              style: {
                                minHeight: _vm.tabHeight * 20 + "px",
                              },
                            },
                            [
                              _vm.isActive(index) && _vm.tableLoading
                                ? _c("v-progress-circular", {
                                    attrs: { indeterminate: "" },
                                  })
                                : _c(
                                    "div",
                                    _vm._l(
                                      tabDef.title.split(` @ `),
                                      function (element, elIndex) {
                                        return _c(
                                          "div",
                                          {
                                            key: element,
                                            staticClass: "text-truncate",
                                          },
                                          [
                                            _vm._v(" " + _vm._s(element) + " "),
                                            elIndex == 0 && tabDef.count > 1
                                              ? _c("span", [
                                                  _vm._v(
                                                    " ( " +
                                                      _vm._s(tabDef.count) +
                                                      " ) "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "pr-1" },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "sliderElementClose",
                                  attrs: {
                                    ripple: false,
                                    color: "error",
                                    size: "20px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.removeTab(index)
                                    },
                                  },
                                },
                                [_vm._v(" mdi-close ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "div",
                  [
                    _c(
                      "v-slide-x-transition",
                      { attrs: { "hide-on-leave": "" } },
                      [
                        _c(
                          "v-card",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isActive(-1),
                                expression: "isActive(-1)",
                              },
                            ],
                            attrs: { flat: "" },
                          },
                          [
                            _c(
                              "v-card-title",
                              { staticClass: "ml-1 mb-1" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex flex-row align-center",
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        ref: "homeCopy",
                                        staticClass: "mr-3",
                                        on: { click: _vm.onCopyHome },
                                      },
                                      [_vm._v(" mdi-content-copy ")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex flex-column mb-1",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "primary--text" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.filterValues?.casinos
                                                    ?.length === 1
                                                    ? `${_vm.casinoParse(
                                                        _vm.filterValues
                                                          .casinos[0]
                                                      )}`
                                                    : ""
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("Capitalize")(_vm.scope)
                                            ) +
                                            " statistics - " +
                                            _vm._s(
                                              _vm.timeToText(_vm.filterValues)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-spacer"),
                                _vm._usrFlagsSome({
                                  key: `statistic`,
                                  val: _vm.permissions.READ,
                                })
                                  ? _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          elevation: "1",
                                          fab: "",
                                          small: "",
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onExportOpen(
                                              "statistics"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-arrow-down-bold-circle"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "v-card-text",
                              { staticClass: "px-1 px-md-3" },
                              [
                                _c("StatisticsTable", {
                                  ref: "stats-home",
                                  attrs: {
                                    disabledFields: _vm.disabledFields,
                                    dataType: "classic",
                                    tabKey: "rootTab",
                                    aggregator: "'casino'",
                                    allowedCasinos: _vm.allowedCasinos,
                                    headers: _vm.perCasinoHeaders,
                                    filter: _vm.filterValues,
                                    loadList: (e, r) =>
                                      _vm._loadPerCasino(e, r),
                                    graphLabels: _vm.graphLabels,
                                    graphType: _vm.graphType,
                                    loadParams: _vm.rootParams,
                                    level: 0,
                                    scope: _vm.scope,
                                    showGraph: _vm.showGraph,
                                    _options: _vm.options,
                                  },
                                  on: {
                                    copyTab: _vm.onCopyHome,
                                    "update:graphLabels": function ($event) {
                                      _vm.graphLabels = $event
                                    },
                                    "update:graph-labels": function ($event) {
                                      _vm.graphLabels = $event
                                    },
                                    "update:graphType": function ($event) {
                                      _vm.graphType = $event
                                    },
                                    "update:graph-type": function ($event) {
                                      _vm.graphType = $event
                                    },
                                    "update:_options": function ($event) {
                                      _vm.options = $event
                                    },
                                    openDetail: _vm.onCasinoRowClick,
                                    addToCompare: _vm.onCasinoCompare,
                                    toggleGraph: _vm.toggleGraph,
                                    loading: _vm.handleLoading,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "exportButton",
                                        fn: function () {
                                          return undefined
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: `item.rtp`,
                                        fn: function ({ item }) {
                                          return [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: {
                                                  disabled:
                                                    !_vm.filterValues.flags.includes(
                                                      "joker_bonus"
                                                    ) ||
                                                    !_vm._usrFlagsSome({
                                                      key: "statistic",
                                                      val: _vm.permissions.READ,
                                                    }),
                                                  bottom: "",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  class:
                                                                    _vm.rtpStyle(
                                                                      item.rtp
                                                                    ),
                                                                },
                                                                "span",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "Percentage"
                                                                    )(item.rtp)
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c(
                                                  "table",
                                                  {
                                                    staticClass: "tooltipTable",
                                                  },
                                                  [
                                                    _c("tr", [
                                                      _c("td", [
                                                        _vm._v("Total:"),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "Percentage"
                                                              )(item.rtp)
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]),
                                                    _c("tr", [
                                                      _c("td", [
                                                        _vm._v("Rounds:"),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "Percentage"
                                                              )(
                                                                _vm.wAvg(
                                                                  item.sum_win,
                                                                  item.sum_bet,
                                                                  item.data
                                                                    .global
                                                                    .joker_bonus
                                                                    .sum_win,
                                                                  false
                                                                ) ?? 0
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]),
                                                    _vm.filterValues.flags.includes(
                                                      "joker_bonus"
                                                    )
                                                      ? _c("tr", [
                                                          _c("td", [
                                                            _vm._v(
                                                              "Joker Bonus:"
                                                            ),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              " +" +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "Percentage"
                                                                  )(
                                                                    _vm.wAvg(
                                                                      item.sum_win,
                                                                      item.sum_bet,
                                                                      item.data
                                                                        .global
                                                                        .joker_bonus
                                                                        .sum_win
                                                                    ) ?? 0
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: `item.rounds_count`,
                                        fn: function ({ item }) {
                                          return [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            _vm._g(
                                                              _vm._b(
                                                                {},
                                                                "span",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "Number"
                                                                    )(
                                                                      item.rounds_count,
                                                                      {
                                                                        style:
                                                                          "decimal",
                                                                        minimumFractionDigits: 0,
                                                                        maximumFractionDigits: 0,
                                                                      }
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c(
                                                  "table",
                                                  {
                                                    staticClass: "tooltipTable",
                                                  },
                                                  [
                                                    _c("tr", [
                                                      _c("td", [
                                                        _vm._v("Classic:"),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f("Number")(
                                                                item.data.global
                                                                  .classic
                                                                  ?.rounds_count,
                                                                {
                                                                  style:
                                                                    "decimal",
                                                                  minimumFractionDigits: 0,
                                                                  maximumFractionDigits: 0,
                                                                }
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]),
                                                    _vm.filterValues.flags.includes(
                                                      "free_round"
                                                    )
                                                      ? _c("tr", [
                                                          _c("td", [
                                                            _vm._v(
                                                              "Free Rounds:"
                                                            ),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "Number"
                                                                  )(
                                                                    item.data
                                                                      .global
                                                                      .free_round
                                                                      ?.rounds_count,
                                                                    {
                                                                      style:
                                                                        "decimal",
                                                                      minimumFractionDigits: 0,
                                                                      maximumFractionDigits: 0,
                                                                    }
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: `item.date_from`,
                                        fn: function ({ item }) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("Date")(item.date_from)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: `item.date_to`,
                                        fn: function ({ item }) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("Date")(item.date_to)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: `item.avg_bet`,
                                        fn: function ({ item }) {
                                          return [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  class:
                                                                    _vm.styleThis(
                                                                      "avg_bet",
                                                                      item.avg_bet
                                                                    ),
                                                                  staticStyle: {
                                                                    position:
                                                                      "relative",
                                                                  },
                                                                },
                                                                "div",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "Number"
                                                                    )(
                                                                      item.avg_bet,
                                                                      {
                                                                        minimumFractionDigits:
                                                                          item.minor_units,
                                                                        maximumFractionDigits:
                                                                          item.minor_units,
                                                                        currency:
                                                                          item.currency ||
                                                                          "EUR",
                                                                      }
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c(
                                                  "table",
                                                  {
                                                    staticClass: "tooltipTable",
                                                  },
                                                  [
                                                    _c("tr", [
                                                      _c("td", [
                                                        _vm._v("Classic:"),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f("Number")(
                                                                item.data.global
                                                                  .classic
                                                                  .avg_bet,
                                                                {
                                                                  minimumFractionDigits:
                                                                    item.minor_units,
                                                                  maximumFractionDigits:
                                                                    item.minor_units,
                                                                  currency:
                                                                    item.currency ||
                                                                    "EUR",
                                                                }
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]),
                                                    _vm.filterValues.flags.includes(
                                                      "free_round"
                                                    )
                                                      ? _c("tr", [
                                                          _c("td", [
                                                            _vm._v(
                                                              "Free Rounds:"
                                                            ),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "Number"
                                                                  )(
                                                                    item.data
                                                                      .global
                                                                      .free_round
                                                                      .avg_bet,
                                                                    {
                                                                      minimumFractionDigits:
                                                                        item.minor_units,
                                                                      maximumFractionDigits:
                                                                        item.minor_units,
                                                                      currency:
                                                                        item.currency ||
                                                                        "EUR",
                                                                    }
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                      _vm._l(
                                        ["sum_bet", "sum_win", "netto"],
                                        function (key) {
                                          return {
                                            key: `item.${key}`,
                                            fn: function ({ item }) {
                                              return [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    key: key,
                                                    attrs: {
                                                      bottom: "",
                                                      disabled:
                                                        _vm.disabledFields.includes(
                                                          key
                                                        ),
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                            attrs,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      key: key,
                                                                      staticClass:
                                                                        "text-no-wrap",
                                                                      class:
                                                                        _vm.styleThis(
                                                                          key,
                                                                          item[
                                                                            key
                                                                          ]
                                                                        ),
                                                                      staticStyle:
                                                                        {
                                                                          position:
                                                                            "relative",
                                                                        },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                            $event.preventDefault()
                                                                            return ((
                                                                              e
                                                                            ) => {
                                                                              _vm.disabledFields.includes(
                                                                                key
                                                                              )
                                                                                ? (_vm.showKProDialog = true)
                                                                                : _vm.onCasinoRowClick(
                                                                                    item,
                                                                                    undefined,
                                                                                    e
                                                                                  )
                                                                            }).apply(
                                                                              null,
                                                                              arguments
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    "div",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "Number"
                                                                        )(
                                                                          item[
                                                                            key
                                                                          ],
                                                                          {
                                                                            minimumFractionDigits:
                                                                              item.minor_units,
                                                                            maximumFractionDigits:
                                                                              item.minor_units,
                                                                            currency:
                                                                              item.currency ||
                                                                              "EUR",
                                                                          }
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "show",
                                                                                rawName:
                                                                                  "v-show",
                                                                                value:
                                                                                  [
                                                                                    "sum_bet",
                                                                                    "sum_win",
                                                                                  ].includes(
                                                                                    key
                                                                                  ) &&
                                                                                  _vm
                                                                                    .filterValues
                                                                                    .flags
                                                                                    .length >
                                                                                    0,
                                                                                expression:
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t['sum_bet', 'sum_win'].includes(key) &&\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tfilterValues.flags.length > 0\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "warning--text pl-1",
                                                                          attrs:
                                                                            {
                                                                              size: "15",
                                                                            },
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        " mdi-information "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c(
                                                      "table",
                                                      {
                                                        staticClass:
                                                          "tooltipTable",
                                                      },
                                                      [
                                                        _c("tr", [
                                                          _c("td", [
                                                            _vm._v("Classic:"),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "Number"
                                                                  )(
                                                                    item.data
                                                                      .global
                                                                      .classic[
                                                                      key
                                                                    ],
                                                                    {
                                                                      minimumFractionDigits:
                                                                        item.minor_units,
                                                                      maximumFractionDigits:
                                                                        item.minor_units,
                                                                      currency:
                                                                        item.currency ||
                                                                        "EUR",
                                                                    }
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ]),
                                                        _vm.filterValues.flags.includes(
                                                          "free_round"
                                                        )
                                                          ? _c("tr", [
                                                              _c("td", [
                                                                _vm._v(
                                                                  "Free Rounds:"
                                                                ),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "Number"
                                                                      )(
                                                                        item
                                                                          .data
                                                                          .global
                                                                          .free_round[
                                                                          key
                                                                        ],
                                                                        {
                                                                          minimumFractionDigits:
                                                                            item.minor_units,
                                                                          maximumFractionDigits:
                                                                            item.minor_units,
                                                                          currency:
                                                                            item.currency ||
                                                                            "EUR",
                                                                        }
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ])
                                                          : _vm._e(),
                                                        [
                                                          "sum_win",
                                                          "netto",
                                                        ].includes(key) &&
                                                        _vm.filterValues.flags.includes(
                                                          "joker_bonus"
                                                        )
                                                          ? _c("tr", [
                                                              _c("td", [
                                                                _vm._v(
                                                                  "Joker Bonus:"
                                                                ),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "Number"
                                                                      )(
                                                                        item
                                                                          .data
                                                                          .global
                                                                          .joker_bonus[
                                                                          key
                                                                        ],
                                                                        {
                                                                          minimumFractionDigits:
                                                                            item.minor_units,
                                                                          maximumFractionDigits:
                                                                            item.minor_units,
                                                                          currency:
                                                                            item.currency ||
                                                                            "EUR",
                                                                        }
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          }
                                        }
                                      ),
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-slide-y-transition",
                      { attrs: { group: "", "hide-on-leave": "" } },
                      [
                        _vm._l(_vm.tabs, function (tabDef, index) {
                          return [
                            _c(
                              "v-card",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isActive(index),
                                    expression: "isActive(index)",
                                  },
                                ],
                                key: `${tabDef.title}-${tabDef.count}`,
                                attrs: { flat: "" },
                              },
                              [
                                _c(
                                  "v-card-title",
                                  { staticClass: "ml-1 mb-1" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-row align-center",
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            ref: "tabCopy",
                                            refInFor: true,
                                            staticClass: "mr-3",
                                            on: {
                                              click: function ($event) {
                                                return _vm.onTabCopy(tabDef)
                                              },
                                            },
                                          },
                                          [_vm._v(" mdi-content-copy ")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "d-flex flex-column" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "primary--text" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      tabDef.filter?.casinos
                                                        ?.length === 1
                                                        ? `${_vm.casinoParse(
                                                            tabDef.filter
                                                              .casinos[0]
                                                          )}`
                                                        : ""
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      tabDef.level == 4
                                                        ? `- ${tabDef.game.name}`
                                                        : ""
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("Capitalize")(
                                                    _vm.scope
                                                  )
                                                ) +
                                                " statistics - " +
                                                _vm._s(
                                                  _vm.timeToText(tabDef.filter)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-spacer"),
                                    _vm._usrFlagsSome({
                                      key: `statistic`,
                                      val: _vm.permissions.READ,
                                    })
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              elevation: "1",
                                              fab: "",
                                              small: "",
                                              color: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onExportOpen(
                                                  "statistics"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                "mdi-arrow-down-bold-circle"
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card-text",
                                  [
                                    _c("StatisticsTable", {
                                      key: `${tabDef.headers.join()}-classic`,
                                      ref: `stats-${index}`,
                                      refInFor: true,
                                      attrs: {
                                        disabledFields: _vm.disabledFields,
                                        dataType: "classic",
                                        headers: tabDef.headers,
                                        loadList: tabDef.loadFn,
                                        tabKey: tabDef.tabKey,
                                        aggregator: tabDef.aggregator,
                                        allowedCasinos: _vm.allowedCasinos,
                                        loadParams: tabDef.loadParams,
                                        filter: tabDef.filter,
                                        showGraph: _vm.showGraph,
                                        level: tabDef.level,
                                        scope: _vm.scope,
                                        graphLabels: tabDef.graphLabels,
                                        graphType: tabDef.graphType,
                                        _options: tabDef.options,
                                      },
                                      on: {
                                        "update:graphLabels": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            tabDef,
                                            "graphLabels",
                                            $event
                                          )
                                        },
                                        "update:graph-labels": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            tabDef,
                                            "graphLabels",
                                            $event
                                          )
                                        },
                                        "update:graphType": function ($event) {
                                          return _vm.$set(
                                            tabDef,
                                            "graphType",
                                            $event
                                          )
                                        },
                                        "update:graph-type": function ($event) {
                                          return _vm.$set(
                                            tabDef,
                                            "graphType",
                                            $event
                                          )
                                        },
                                        "update:_options": function ($event) {
                                          return _vm.$set(
                                            tabDef,
                                            "options",
                                            $event
                                          )
                                        },
                                        openDetail: tabDef.onRowClick,
                                        toggleGraph: _vm.toggleGraph,
                                        addToCompare: tabDef.onCompare,
                                        loading: _vm.handleLoading,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: `item.rtp`,
                                            fn: function ({ item, extraData }) {
                                              return [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        !tabDef.filter.flags.includes(
                                                          "joker_bonus"
                                                        ) ||
                                                        !_vm._usrFlagsSome({
                                                          key: "statistic",
                                                          val: _vm.permissions
                                                            .READ,
                                                        }),
                                                      bottom: "",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                            attrs,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "span",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      class:
                                                                        _vm.styleThis(
                                                                          "rtp",
                                                                          item.rtp
                                                                        ),
                                                                    },
                                                                    "span",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "Percentage"
                                                                        )(
                                                                          item.rtp
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c(
                                                      "table",
                                                      {
                                                        staticClass:
                                                          "tooltipTable",
                                                      },
                                                      [
                                                        _c("tr", [
                                                          _c("td", [
                                                            _vm._v("Total:"),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "Percentage"
                                                                  )(item.rtp)
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ]),
                                                        _c("tr", [
                                                          _c("td", [
                                                            _vm._v("Rounds:"),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "Percentage"
                                                                  )(
                                                                    _vm.wAvg(
                                                                      item.sum_win,
                                                                      item.sum_bet,
                                                                      extraData.joker_bonus.find(
                                                                        (el) =>
                                                                          el[
                                                                            tabDef
                                                                              .headers[0]
                                                                              .value
                                                                          ] ==
                                                                          item[
                                                                            tabDef
                                                                              .headers[0]
                                                                              .value
                                                                          ]
                                                                      )
                                                                        ?.sum_win,
                                                                      false
                                                                    ) ?? 0
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ]),
                                                        tabDef.filter.flags.includes(
                                                          "joker_bonus"
                                                        )
                                                          ? _c("tr", [
                                                              _c("td", [
                                                                _vm._v(
                                                                  "Joker Bonus:"
                                                                ),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  " +" +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "Percentage"
                                                                      )(
                                                                        _vm.wAvg(
                                                                          item.sum_win,
                                                                          item.sum_bet,
                                                                          extraData.joker_bonus.find(
                                                                            (
                                                                              el
                                                                            ) =>
                                                                              el[
                                                                                tabDef
                                                                                  .headers[0]
                                                                                  .value
                                                                              ] ==
                                                                              item[
                                                                                tabDef
                                                                                  .headers[0]
                                                                                  .value
                                                                              ]
                                                                          )
                                                                            ?.sum_win
                                                                        ) ?? 0
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: `item.rounds_count`,
                                            fn: function ({ item, extraData }) {
                                              return [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                            attrs,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "span",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {},
                                                                    "span",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "Number"
                                                                        )(
                                                                          item.rounds_count,
                                                                          {
                                                                            style:
                                                                              "decimal",
                                                                            minimumFractionDigits: 0,
                                                                            maximumFractionDigits: 0,
                                                                          }
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c(
                                                      "table",
                                                      {
                                                        staticClass:
                                                          "tooltipTable",
                                                      },
                                                      [
                                                        _c("tr", [
                                                          _c("td", [
                                                            _vm._v("Classic:"),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "Number"
                                                                  )(
                                                                    extraData.classic.find(
                                                                      (el) =>
                                                                        el[
                                                                          tabDef
                                                                            .headers[0]
                                                                            .value
                                                                        ] ==
                                                                        item[
                                                                          tabDef
                                                                            .headers[0]
                                                                            .value
                                                                        ]
                                                                    )
                                                                      ?.rounds_count,
                                                                    {
                                                                      style:
                                                                        "decimal",
                                                                      minimumFractionDigits: 0,
                                                                      maximumFractionDigits: 0,
                                                                    }
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ]),
                                                        tabDef.filter.flags.includes(
                                                          "free_round"
                                                        )
                                                          ? _c("tr", [
                                                              _c("td", [
                                                                _vm._v(
                                                                  "Free Rounds:"
                                                                ),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "Number"
                                                                      )(
                                                                        extraData.free_round.find(
                                                                          (
                                                                            el
                                                                          ) =>
                                                                            el[
                                                                              tabDef
                                                                                .headers[0]
                                                                                .value
                                                                            ] ==
                                                                            item[
                                                                              tabDef
                                                                                .headers[0]
                                                                                .value
                                                                            ]
                                                                        )
                                                                          ?.rounds_count,
                                                                        {
                                                                          style:
                                                                            "decimal",
                                                                          minimumFractionDigits: 0,
                                                                          maximumFractionDigits: 0,
                                                                        }
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: `item.date_from`,
                                            fn: function ({ item }) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("Date")(
                                                        item.date_from,
                                                        _vm.dateFormat
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: `item.date_to`,
                                            fn: function ({ item }) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("Date")(
                                                        item.date_to
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: `item.avg_bet`,
                                            fn: function ({ item, extraData }) {
                                              return [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                            attrs,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      class:
                                                                        _vm.styleThis(
                                                                          "avg_bet",
                                                                          item.avg_bet
                                                                        ),
                                                                      staticStyle:
                                                                        {
                                                                          position:
                                                                            "relative",
                                                                        },
                                                                    },
                                                                    "div",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "Number"
                                                                        )(
                                                                          item.avg_bet,
                                                                          {
                                                                            minimumFractionDigits:
                                                                              item.minor_units,
                                                                            maximumFractionDigits:
                                                                              item.minor_units,
                                                                            currency:
                                                                              item.currency ||
                                                                              "EUR",
                                                                          }
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c(
                                                      "table",
                                                      {
                                                        staticClass:
                                                          "tooltipTable",
                                                      },
                                                      [
                                                        _c("tr", [
                                                          _c("td", [
                                                            _vm._v("Classic:"),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "Number"
                                                                  )(
                                                                    extraData.classic.find(
                                                                      (el) =>
                                                                        el[
                                                                          tabDef
                                                                            .headers[0]
                                                                            .value
                                                                        ] ==
                                                                        item[
                                                                          tabDef
                                                                            .headers[0]
                                                                            .value
                                                                        ]
                                                                    )?.avg_bet,
                                                                    {
                                                                      minimumFractionDigits:
                                                                        item.minor_units,
                                                                      maximumFractionDigits:
                                                                        item.minor_units,
                                                                      currency:
                                                                        item.currency ||
                                                                        "EUR",
                                                                    }
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ]),
                                                        tabDef.filter.flags.includes(
                                                          "free_round"
                                                        )
                                                          ? _c("tr", [
                                                              _c("td", [
                                                                _vm._v(
                                                                  "Free Rounds:"
                                                                ),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "Number"
                                                                      )(
                                                                        extraData.free_round.find(
                                                                          (
                                                                            el
                                                                          ) =>
                                                                            el[
                                                                              tabDef
                                                                                .headers[0]
                                                                                .value
                                                                            ] ==
                                                                            item[
                                                                              tabDef
                                                                                .headers[0]
                                                                                .value
                                                                            ]
                                                                        )
                                                                          ?.avg_bet,
                                                                        {
                                                                          minimumFractionDigits:
                                                                            item.minor_units,
                                                                          maximumFractionDigits:
                                                                            item.minor_units,
                                                                          currency:
                                                                            item.currency ||
                                                                            "EUR",
                                                                        }
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          _vm._l(
                                            ["sum_bet", "sum_win", "netto"],
                                            function (key) {
                                              return {
                                                key: `item.${key}`,
                                                fn: function ({
                                                  item,
                                                  extraData,
                                                }) {
                                                  return [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        key: key,
                                                        attrs: {
                                                          bottom: "",
                                                          disabled:
                                                            _vm.disabledFields.includes(
                                                              key
                                                            ),
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                                attrs,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "text-no-wrap",
                                                                          class:
                                                                            _vm.styleThis(
                                                                              key,
                                                                              item[
                                                                                key
                                                                              ]
                                                                            ),
                                                                          staticStyle:
                                                                            {
                                                                              position:
                                                                                "relative",
                                                                            },
                                                                        },
                                                                        "div",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm._f(
                                                                              "Number"
                                                                            )(
                                                                              item[
                                                                                key
                                                                              ],
                                                                              {
                                                                                minimumFractionDigits:
                                                                                  item.minor_units,
                                                                                maximumFractionDigits:
                                                                                  item.minor_units,
                                                                                currency:
                                                                                  item.currency ||
                                                                                  "EUR",
                                                                              }
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "show",
                                                                                    rawName:
                                                                                      "v-show",
                                                                                    value:
                                                                                      [
                                                                                        "sum_bet",
                                                                                        "sum_win",
                                                                                      ].includes(
                                                                                        key
                                                                                      ) &&
                                                                                      tabDef
                                                                                        .filter
                                                                                        .flags
                                                                                        .length >
                                                                                        0,
                                                                                    expression:
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t['sum_bet', 'sum_win'].includes(key) &&\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\ttabDef.filter.flags.length > 0\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t",
                                                                                  },
                                                                                ],
                                                                              staticClass:
                                                                                "warning--text pl-1",
                                                                              attrs:
                                                                                {
                                                                                  size: "15",
                                                                                },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            " mdi-information "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _c(
                                                          "table",
                                                          {
                                                            staticClass:
                                                              "tooltipTable",
                                                          },
                                                          [
                                                            _c("tr", [
                                                              _c("td", [
                                                                _vm._v(
                                                                  "Classic:"
                                                                ),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "Number"
                                                                      )(
                                                                        extraData.classic.find(
                                                                          (
                                                                            el
                                                                          ) =>
                                                                            el[
                                                                              tabDef
                                                                                .headers[0]
                                                                                .value
                                                                            ] ==
                                                                            item[
                                                                              tabDef
                                                                                .headers[0]
                                                                                .value
                                                                            ]
                                                                        )?.[
                                                                          key
                                                                        ],
                                                                        {
                                                                          minimumFractionDigits:
                                                                            item.minor_units,
                                                                          maximumFractionDigits:
                                                                            item.minor_units,
                                                                          currency:
                                                                            item.currency ||
                                                                            "EUR",
                                                                        }
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ]),
                                                            tabDef.filter.flags.includes(
                                                              "free_round"
                                                            )
                                                              ? _c("tr", [
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      "Free Rounds:"
                                                                    ),
                                                                  ]),
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "Number"
                                                                          )(
                                                                            extraData.free_round.find(
                                                                              (
                                                                                el
                                                                              ) =>
                                                                                el[
                                                                                  tabDef
                                                                                    .headers[0]
                                                                                    .value
                                                                                ] ==
                                                                                item[
                                                                                  tabDef
                                                                                    .headers[0]
                                                                                    .value
                                                                                ]
                                                                            )?.[
                                                                              key
                                                                            ],
                                                                            {
                                                                              minimumFractionDigits:
                                                                                item.minor_units,
                                                                              maximumFractionDigits:
                                                                                item.minor_units,
                                                                              currency:
                                                                                item.currency ||
                                                                                "EUR",
                                                                            }
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]),
                                                                ])
                                                              : _vm._e(),
                                                            [
                                                              "sum_win",
                                                              "netto",
                                                            ].includes(key) &&
                                                            tabDef.filter.flags.includes(
                                                              "joker_bonus"
                                                            )
                                                              ? _c("tr", [
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      "Joker Bonus:"
                                                                    ),
                                                                  ]),
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "Number"
                                                                          )(
                                                                            extraData.joker_bonus.find(
                                                                              (
                                                                                el
                                                                              ) =>
                                                                                el[
                                                                                  tabDef
                                                                                    .headers[0]
                                                                                    .value
                                                                                ] ==
                                                                                item[
                                                                                  tabDef
                                                                                    .headers[0]
                                                                                    .value
                                                                                ]
                                                                            )?.[
                                                                              key
                                                                            ],
                                                                            {
                                                                              minimumFractionDigits:
                                                                                item.minor_units,
                                                                              maximumFractionDigits:
                                                                                item.minor_units,
                                                                              currency:
                                                                                item.currency ||
                                                                                "EUR",
                                                                            }
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]),
                                                                ])
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              }
                                            }
                                          ),
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-slide-y-transition",
                [
                  _vm.compareItems !== null &&
                  _vm.compareItems !== undefined &&
                  _vm.compareItems.length > 0
                    ? _c("CompareCard", {
                        ref: "noFunc",
                        attrs: {
                          loading: false,
                          items: _vm.compareItems,
                          rows: _vm.compareRows,
                        },
                        on: { closeAll: _vm.closeAllCompare },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: `row.title.value`,
                              fn: function ({ item, index }) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-column" },
                                        _vm._l(
                                          item.title.split(` @ `),
                                          function (element, elIndex) {
                                            return _c(
                                              "div",
                                              {
                                                key: element,
                                                staticClass: "text-truncate",
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(element) + " "
                                                ),
                                                elIndex == 0 && item.count > 1
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " ( " +
                                                          _vm._s(item.count) +
                                                          " ) "
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mb-1",
                                              attrs: {
                                                text: "",
                                                fab: "",
                                                "x-small": "",
                                                color: "error",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.removeCompareTab(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-close"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: `row.rtp.value`,
                              fn: function ({ item, diff }) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm._f("Percentage")(item.rtp)) +
                                        " "
                                    ),
                                    diff !== 0
                                      ? _c(
                                          "span",
                                          {
                                            class: {
                                              "error--text": diff < 0,
                                              "success--text": diff > 0,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " (" +
                                                _vm._s(diff > 0 ? "+" : "-") +
                                                _vm._s(
                                                  _vm._f("Number")(
                                                    Math.abs(diff),
                                                    {
                                                      style: "decimal",
                                                      minimumFractionDigits: 3,
                                                      maximumFractionDigits: 3,
                                                    }
                                                  )
                                                ) +
                                                ") "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: `row.rounds_count.value`,
                              fn: function ({ item, diff }) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("Number")(item.rounds_count, {
                                            style: "decimal",
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                          })
                                        ) +
                                        " "
                                    ),
                                    diff !== 0
                                      ? _c(
                                          "span",
                                          {
                                            class: {
                                              "error--text": diff < 0,
                                              "success--text": diff > 0,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " (" +
                                                _vm._s(diff > 0 ? "+" : "-") +
                                                _vm._s(
                                                  _vm._f("Number")(
                                                    Math.abs(diff),
                                                    {
                                                      style: "decimal",
                                                      minimumFractionDigits: 0,
                                                      maximumFractionDigits: 0,
                                                    }
                                                  )
                                                ) +
                                                ") "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: `row.currency.value`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(item.currency || "EUR") + " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `row.avg_bet.value`,
                              fn: function ({ item, diff, items }) {
                                return [
                                  _c(
                                    "span",
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("Number")(item.avg_bet, {
                                              minimumFractionDigits:
                                                item.minor_units,
                                              maximumFractionDigits:
                                                item.minor_units,
                                              currency: item.currency || "EUR",
                                            })
                                          ) +
                                          " "
                                      ),
                                      diff != 0
                                        ? [
                                            (item.currency || "EUR") ===
                                            (items[0].currency || "EUR")
                                              ? _c(
                                                  "span",
                                                  {
                                                    class: {
                                                      "error--text": diff < 0,
                                                      "success--text": diff > 0,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " (" +
                                                        _vm._s(
                                                          diff > 0 ? "+" : "-"
                                                        ) +
                                                        _vm._s(
                                                          _vm._f("Number")(
                                                            Math.abs(diff),
                                                            {
                                                              minimumFractionDigits:
                                                                item.minor_units,
                                                              maximumFractionDigits:
                                                                item.minor_units,
                                                              currency:
                                                                item.currency ||
                                                                "EUR",
                                                            }
                                                          )
                                                        ) +
                                                        ") "
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      bottom: "",
                                                      color: "warning darken-2",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "span",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "warning--text",
                                                                  },
                                                                  on
                                                                ),
                                                                [_vm._v(" ? ")]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Currencies doesn't match"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]
                              },
                            },
                            _vm._l(
                              ["sum_bet", "sum_win", "netto"],
                              function (key) {
                                return {
                                  key: `row.${key}.value`,
                                  fn: function ({ item, diff, items }) {
                                    return [
                                      _c(
                                        "span",
                                        { key: key },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "ml-1",
                                              class: _vm.styleThis(key),
                                              staticStyle: {
                                                position: "relative",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("Number")(
                                                      item[key],
                                                      {
                                                        minimumFractionDigits:
                                                          item.minor_units,
                                                        maximumFractionDigits:
                                                          item.minor_units,
                                                        currency:
                                                          item.currency ||
                                                          "EUR",
                                                      }
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          diff != 0
                                            ? [
                                                (item.currency || "EUR") ===
                                                  (items[0].currency ||
                                                    "EUR") &&
                                                _vm._usrFlagsSome({
                                                  key: `statistic`,
                                                  val: _vm.permissions.READ,
                                                })
                                                  ? _c(
                                                      "span",
                                                      {
                                                        class: {
                                                          "error--text":
                                                            diff < 0,
                                                          "success--text":
                                                            diff > 0,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " (" +
                                                            _vm._s(
                                                              diff > 0
                                                                ? "+"
                                                                : "-"
                                                            ) +
                                                            _vm._s(
                                                              _vm._f("Number")(
                                                                Math.abs(diff),
                                                                {
                                                                  minimumFractionDigits:
                                                                    item.minor_units,
                                                                  maximumFractionDigits:
                                                                    item.minor_units,
                                                                  currency:
                                                                    item.currency ||
                                                                    "EUR",
                                                                }
                                                              )
                                                            ) +
                                                            ") "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._usrFlagsSome({
                                                      key: `statistic`,
                                                      val: _vm.permissions.READ,
                                                    })
                                                  ? _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          bottom: "",
                                                          color:
                                                            "warning darken-2",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "span",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "warning--text",
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        " ? "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Currencies doesn't match"
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]
                                  },
                                }
                              }
                            ),
                          ],
                          null,
                          true
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          on: {
            "click:outside": _vm.exportClose,
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.exportClose.apply(null, arguments)
            },
          },
          model: {
            value: _vm.exportDialog,
            callback: function ($$v) {
              _vm.exportDialog = $$v
            },
            expression: "exportDialog",
          },
        },
        [
          _c("ExportDialog", {
            ref: "statsExport",
            attrs: {
              builder: _vm.statsType,
              showSettings: "",
              data: _vm.statsExportData,
              filename: _vm.filename,
              settingItems: [
                { name: "Classic", id: "classic" },
                {
                  name: "Special",
                  id: "special",
                  children: [
                    { name: "Free Rounds", id: "free_round" },
                    { name: "Joker Bonus", id: "joker_bonus" },
                  ],
                },
              ],
            },
            on: { close: _vm.exportClose },
            scopedSlots: _vm._u(
              [
                _vm.exportDialog
                  ? {
                      key: "settings",
                      fn: function ({ settingItems, changeModel }) {
                        return [
                          _c(
                            "h2",
                            {
                              staticClass:
                                "d-flex flex-row justify-center mt-n10",
                            },
                            [_vm._v(" Select statistics to export ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-row ml-8 mt-5 justify-space-around",
                            },
                            _vm._l(settingItems, function (tree, index) {
                              return _c(
                                "div",
                                {
                                  key: tree.name,
                                  staticClass: "d-flex mr-9 mt-5",
                                },
                                [
                                  index > 0
                                    ? _c("v-divider", {
                                        attrs: { vertical: "" },
                                      })
                                    : _vm._e(),
                                  _c("v-treeview", {
                                    attrs: {
                                      "selected-color": "primary",
                                      open: ["special"],
                                      selectable: "",
                                      items: [tree],
                                    },
                                    on: {
                                      input: (e) => changeModel(e, tree.id),
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _c("div", { staticClass: "mt-10" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex flex-row align-center" },
                              [
                                _c("v-checkbox", {
                                  staticClass: "mr-2 mt-0 mb-0",
                                  attrs: { "hide-details": "" },
                                  model: {
                                    value: _vm.exportCategories,
                                    callback: function ($$v) {
                                      _vm.exportCategories = $$v
                                    },
                                    expression: "exportCategories",
                                  },
                                }),
                                _vm._v(" Export Categories "),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-row align-center" },
                              [
                                _c("v-checkbox", {
                                  staticClass: "mr-2 mt-0",
                                  attrs: { "hide-details": "" },
                                  model: {
                                    value: _vm.exportRemoved,
                                    callback: function ($$v) {
                                      _vm.exportRemoved = $$v
                                    },
                                    expression: "exportRemoved",
                                  },
                                }),
                                _vm._v(" Export Removed Games "),
                              ],
                              1
                            ),
                          ]),
                        ]
                      },
                    }
                  : null,
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          staticStyle: {
            position: "fixed",
            bottom: "20px",
            "text-align": "center",
          },
          attrs: {
            "content-class": "kajot-text--text",
            app: "",
            transition: "slide-y-reverse-transition",
            color: "menu_background",
            timeout: _vm.snackTimeout,
          },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-5",
                      attrs: { small: "", text: "", color: "primary" },
                      on: { click: _vm.scrollToCompare },
                    },
                    [_vm._v(" Scroll to ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.comparionSnack,
            callback: function ($$v) {
              _vm.comparionSnack = $$v
            },
            expression: "comparionSnack",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center justify-center" },
            [
              _c(
                "v-icon",
                { staticClass: "mr-5", attrs: { color: "kajot-text" } },
                [_vm._v(" mdi-arrow-down-bold ")]
              ),
              _c("span", { staticClass: "ml-4" }, [
                _vm._v("Added to comparison window"),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500px" },
          model: {
            value: _vm.showKProDialog,
            callback: function ($$v) {
              _vm.showKProDialog = $$v
            },
            expression: "showKProDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-center" },
                  [
                    _vm.$vuetify.theme.dark
                      ? _c("v-img", {
                          attrs: {
                            width: "250px",
                            src: "../../kpluswhite.png",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex justify-center" },
                  [
                    !_vm.$vuetify.theme.dark
                      ? _c("v-img", {
                          attrs: {
                            width: "250px",
                            src: "../../kplusblack.png",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("v-card-text", [
                _c(
                  "div",
                  { staticClass: "d-flex flex-row justify-center mb-3" },
                  [
                    _vm._v(" Purchase "),
                    _c("span", { staticClass: "font-weight-bold mx-1" }, [
                      _vm._v(" KAJOT "),
                      _c("span", { staticStyle: { color: "#fecb4d" } }, [
                        _vm._v("PLUS"),
                      ]),
                    ]),
                    _vm._v(" to display casino data. "),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "border-radius": "25px",
                      border: "2px solid #fecb4d",
                      padding: "35px",
                    },
                  },
                  [
                    _c("div", { staticClass: "font-weight-bold text-h5" }, [
                      _vm._v("Solo"),
                    ]),
                    _c("div", [_vm._v("For small time analyst dudes.")]),
                    _c(
                      "div",
                      { staticClass: "font-weight-bold text-h4 my-4" },
                      [_vm._v("$269")]
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row" },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-2",
                            attrs: { color: "#fecb4d", size: "16" },
                          },
                          [_vm._v(" mdi-check-circle ")]
                        ),
                        _c("div", [_vm._v("One analyst")]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row" },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-2",
                            attrs: { color: "#fecb4d", size: "16" },
                          },
                          [_vm._v(" mdi-check-circle ")]
                        ),
                        _c("div", [_vm._v("Unlimited casinos")]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row" },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-2",
                            attrs: { color: "#fecb4d", size: "16" },
                          },
                          [_vm._v(" mdi-check-circle ")]
                        ),
                        _c("div", [_vm._v("Better yellow design")]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row" },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-2",
                            attrs: { color: "#fecb4d", size: "16" },
                          },
                          [_vm._v(" mdi-check-circle ")]
                        ),
                        _c("div", [_vm._v("Unlimited minor & patch updates")]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row mb-8" },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-2",
                            attrs: { color: "#fecb4d", size: "16" },
                          },
                          [_vm._v(" mdi-check-circle ")]
                        ),
                        _c("div", [_vm._v("Lifetime access")]),
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "black--text",
                        attrs: { color: "#FECB4D" },
                      },
                      [_vm._v(" Buy Now ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }